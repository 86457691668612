// This function purpose is to retrieve a key:value
// from a URL.

import moment from "moment";
import { config } from "../config";

// URL should be the {window} object
export function URLQuery(variable:string, URL:any) {
  var query = URL.location.search.substring(1);
  var vars = query.split("&");

  for (var i=0;i<vars.length;i++) {
    var pair = vars[i].split("=");
    if(pair[0] === variable) return pair[1];
  }

  return(false);
}

export function formatDateTime(dateTime:string) {
  var dateTime = dateTime;

  if(!dateTime) return '-';

  if(typeof dateTime !== 'string') return '-';

  try {
    dateTime = moment(dateTime).format(config.momentDateTimeFormat);
    return dateTime;
  }
  catch(e) {
    console.error('formatDateTime Error', e);
    return '-';
  }
}

export function formatDate(dateTime:string) {
  var dateTime = dateTime;

  if(!dateTime) return '-';

  if(typeof dateTime !== 'string') return '-';

  try {
    dateTime = moment(dateTime).format(config.momentDateFormat);
    return dateTime;
  }
  catch(e) {
    console.error('formatDateTime Error', e);
    return '-';
  }
}

export function displayPrice(price:number|string) {
  if(!price) return '0.00';

  var processedPrice = price;
  processedPrice = parseFloat(price.toString());

  return processedPrice.toFixed(2);
}