
// import config
import { useEffect, useState } from 'react';
import { getI18n, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { config } from '../config';

import { 
  Button, 
  Divider, 
  Form, 
  Header, 
  Segment, 
  Modal, 
  Grid, 
  Dropdown, 
  GridRow, 
  Label,
  Table,
  Input,
  Menu,
  Icon,
  Loader,
  ItemDescription,
  Popup,
  Progress
} from 'semantic-ui-react';

import DatePicker from 'react-datepicker';

import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';

import RLCustomer from '../util/funcs/customers';

import { displayPrice, formatDate } from "../util/tools";
import ReeLaAPI from '../util/reela_lib';
import RSettings from "../util/funcs/settings"


const CartItem = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [cartItemQuantity, setCartItemQuantity] = useState<any>(props.cartItem.quantity|0); 

  const updateCartItem = async (action:'delete'|'update', cartItemId:any, quantity:number|null) => {

    if(!['delete', 'update'].includes(action)) {
      if(process.env.NODE_MODE === 'development') console.error('wrong action type');
      return null;
    }

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.cartItemId = cartItemId;

    var res:any = null;

    if(action === 'delete' || Number(quantity) === 0) {
      res = await reelaApi.deleteItemFromCart();
    }

    if(action === 'update') {
      if(Number(quantity) > 0) {
        reelaApi.quantity = quantity;

        res = await reelaApi.updateItemInCart();
      }
      else {
        props.setModal({
          visible: true,
          title: t('g.failedToProcessRequest'),
          message: t('g.missingRequirements'),
          actions: [
            {
              key: 0,
              content: t("g.ok"),
              onClick: () => null
            }
          ]
        });
      }
    }
    
    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');
    }
    else if (res.status === "fail") {
      props.setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            key: 0,
            content: t("g.tryAgain"),
            onClick: () => updateCartItem(action,cartItemId,quantity)
          }
        ]
      });
    }
    else if (res.status === "success") {
      props.setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [
          {
            key: 0,
            positive: true,
            content: t("g.ok"),
            onClick: () => null
          }
        ]
      });
    }

    if(await props.getCart() === true){
      return null;
    }
  }

  var itemName:any = props.cartItem.name_local;

  if(typeof itemName === 'string') {
    try {
      itemName = JSON.parse(itemName);
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') console.warn('error with parsing item name',itemName,e);
      itemName = {en: null, ar: null};
    }
  }

  var printedItemName = [];

  for(const [key, value] of Object.entries(itemName)) {
    printedItemName.push(<div>{String(value)}</div>);
  }

  if(typeof itemName === 'object') {
    itemName = <div>
      {printedItemName}
    </div>
  }

  if (props.screenLayout === 'table') {
    return(
      <Table.Row>
        <Table.Cell>
          {props.cartItem.product_id}
        </Table.Cell>
        <Table.Cell>
          {props.cartItem.sku}
        </Table.Cell>
        <Table.Cell>
          {props.cartItem.barcode}
        </Table.Cell>
        <Table.Cell>
          {itemName}
        </Table.Cell>
        <Table.Cell>
          {displayPrice(props.cartItem.price)}
        </Table.Cell>
        <Table.Cell>
          {displayPrice(props.cartItem.price_inc_tax)}
        </Table.Cell>
        <Table.Cell>
          {displayPrice(props.cartItem.total)}
        </Table.Cell>
        <Table.Cell>
          {displayPrice(props.cartItem.total_inc_tax)}
        </Table.Cell>
        <Table.Cell>
          <Input 
            fluid
            value={cartItemQuantity}
            onChange={(e,data) => {
              setCartItemQuantity(data.value)
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Button 
            primary 
            icon='save'
            onClick={(e,data) => {updateCartItem('update',props.cartItem.id, cartItemQuantity)}}
          />
          <Button 
            negative 
            icon='trash'
            onClick={(e,data) => {
              props.setModal({
                visible: true,
                title: t('g.areYouSure'),
                message: t('s.carts.deleteCartItemDescription'),
                actions: [
                  {
                    key: 0,
                    content: t('g.cancel'),
                    onClick: () => null
                  },
                  {
                    key:0,
                    negative: true,
                    content: t('g.delete'),
                    onClick: () => updateCartItem('delete',props.cartItem.id, null)
                  },
                ]
              })}
            }
          />
        </Table.Cell>
      </Table.Row>
    )
  }
  else {
    return <>
      <Divider hidden />
      <Segment>
        <Grid columns={2}>
          <Grid.Row width='equal'>
            <Grid.Column>
              <div style={{fontWeight: 'bold'}}>
                #{props.cartItem.product_id}
              </div>
              <div>{itemName}</div>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button 
                negative 
                icon='trash'
                content={t('g.delete')}
                onClick={(e,data) => {
                  props.setModal({
                    visible: true,
                    title: t('g.areYouSure'),
                    message: t('s.carts.deleteCartItemDescription'),
                    actions: [
                      {
                        key: 0,
                        content: t('g.cancel'),
                        onClick: () => null
                      },
                      {
                        key: 1,
                        negative: true,
                        content: t('g.delete'),
                        onClick: () => updateCartItem('delete',props.cartItem.id, null)
                      }
                    ]
                  })}
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width='equal'>
            <Grid.Column>
              <div><strong>{t('g.sku')}</strong></div>
              <div>{props.cartItem.sku}</div>
            </Grid.Column>
            <Grid.Column>
              <div><strong>{t('g.barcode')}</strong></div>
              <div>
                {props.cartItem.barcode}
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width='equal'>
            <Grid.Column>
              <div><strong>{t('s.orders.price')}</strong></div>
              <div>{displayPrice(props.cartItem.price)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
            </Grid.Column>
            <Grid.Column>
              <div><strong>{t('s.orders.pit_full')}</strong></div>
              <div>{displayPrice(props.cartItem.price_inc_tax)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width='equal'>
            <Grid.Column>
              <div><strong>{t('s.orders.tatal')}</strong></div>
              <div>{displayPrice(props.cartItem.total)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
            </Grid.Column>
            <Grid.Column>
              <div><strong>{t('s.orders.tit_full')}</strong></div>
              <div>{displayPrice(props.cartItem.total_inc_tax)} {t(`c.${localStorage.getItem('currency-key')}`)}</div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row width='equal'>
            <Grid.Column>
              <Input 
                fluid
                value={cartItemQuantity}
                onChange={(e,data) => {
                  setCartItemQuantity(data.value)
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Button 
                primary 
                icon='save'
                content={t('g.update')}
                onClick={(e,data) => {updateCartItem('update',props.cartItem.id, cartItemQuantity)}}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  }
}

const ProductListingRow = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [productQuantity, setProductQuantity] = useState<any>(0); 

  var productName:any = props.product.name_local;

  if(typeof productName === 'string') {
    try {
      productName = JSON.parse(productName);
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') console.warn(e);
      productName = {en: null, ar: null};
    }
  }

  var printedProductName = [];

  for(const [key, value] of Object.entries(productName)) {
    printedProductName.push(<div>{String(value)}</div>);
  }

  if(typeof productName === 'object') {
    productName = <div>
      {printedProductName}
    </div>
  }

  const addItemToCart = async (cartUuid:string, productId:number, quantity:number) => {

    if(!quantity || quantity < 1){
      if(process.env.NODE_MODE === 'development') console.error('cannot add a product with that quantity', quantity);
      return null;
    }

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.cartUuid = cartUuid
    reelaApi.productId = productId
    reelaApi.quantity = quantity

    var res:any = await reelaApi.addItemToCart();
    
    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');
    }
    else if (res.status === "fail") {
      props.setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        actions: [
          {
            key: 0,
            content: t("g.tryAgain"),
            onClick: () => addItemToCart(cartUuid, productId, quantity)
          }
        ]
      });
    }
    else if (res.status === "success") {
      props.setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [
          {
            key: 0,
            positive: true,
            content: t("g.ok"),
            onClick: () => null
          }
        ]
      });
    }

    if(await props.getCart()){
      return null;
    }
  }

  if(props.screenLayout === "table") {
    return(
      <Table.Row>
        <Table.Cell>
          {props.product?.id}
        </Table.Cell>
        <Table.Cell>
          {props.product?.sku}
        </Table.Cell>
        <Table.Cell>
          {props.product?.barcode}
        </Table.Cell>
        <Table.Cell>
          {productName}
        </Table.Cell>
        <Table.Cell>
          <Input 
            fluid
            value={productQuantity}
            onChange={(e,data) => {
              setProductQuantity(data.value)
            }}
          />
        </Table.Cell>
        <Table.Cell>
          <Button 
            primary 
            icon='plus'
            onClick={(e,data) => {addItemToCart(props.cart.uuid, props.product.id, Number(productQuantity))}}
          />
        </Table.Cell>
      </Table.Row>
    )
  }
  else {
    return(
      <>
        <Divider hidden />
        <Segment>
          <Grid columns={2}>
            <Grid.Row width='equal'>
              <Grid.Column>
                <div><strong>{t('g.name')}</strong></div>
                <div>{productName}</div>
              </Grid.Column>
              <Grid.Column>
                <div><strong>{t('g.sku')}</strong></div>
                <div>{props.product?.sku}</div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row width='equal'>
              <Grid.Column>
                <div><strong>{t('g.barcode')}</strong></div>
                <div>
                  {props.product?.barcode}
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row width='equal'>
              <Grid.Column>
                <Input 
                  fluid
                  value={productQuantity}
                  onChange={(e,data) => {
                    setProductQuantity(data.value)
                  }}
                />
              </Grid.Column>
              <Grid.Column>
                <Button 
                  primary 
                  icon='plus'
                  content="add"
                  onClick={(e,data) => {addItemToCart(props.cart.uuid, props.product.id, Number(productQuantity))}}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }
}

const CartForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  // check URL params
  let { cartUuid } = useParams<any>();

  let profile:any = null;
  let management:boolean = false;
  let registrar:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;
    if(profile.type === 'registrar') registrar = true;

    if(!management && !registrar) navigate(-1);
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);
  }

  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [products, setProducts] = useState<any>(null);

  const [loadingCart, setLoadingCart] = useState<boolean>(false);
  const [cart, setCart] = useState<any>();

  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Array<any>|null>(null);
  const [customerId, setCustomerId] = useState<number|null>(null);

  const [settings, setSettings] = useState<Array<any>>([]);
  const [loadingSettings, setLoadingSettings] = useState<boolean>(false);

  const [deliveryShift, setDeliveryShift] = useState<1|2|3>(1);
  const [deliveryDate, setDeliveryDate] = useState<Date|null>();
    
  const [searchTerm, setSearchTerm] = useState<string|null>(null);

  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    if(window.innerWidth <= 768) {
      setScreenLayout('cards');
    }
    else setScreenLayout('table');

    getSetting("minimum-sub-for-free-shipping");
    getSetting("minimum-sub-for-order");

    getCart();
    if(!cartUuid) getCustomers(); 
  }, []);

  useEffect(() => {
    if(searchTerm) getProducts();
  }, [
    searchTerm
  ]);
  
  const getProducts = async () => {
    // get the products listing
    if(loadingProducts) return null
    setLoadingProducts(true);
    setProducts([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.searchTerm = searchTerm;
    reelaApi.customerId = cart.cart.customer_id;
    reelaApi.isAvailable = 'y';

    var res:any = await reelaApi.getProducts();

    setLoadingProducts(false);
    
    if(res && res.data) {
      setProducts(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t("g.ok") }]
    })
  }

  const getCart = async () => {
    // get the cart
    if(loadingCart) return null;
    if(!cartUuid) return null;
    setLoadingCart(true);
    setCart([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.cartUuid = cartUuid;

    var res:any = await reelaApi.getCart();
    
    if(res && res.data) {

      // handling the customer name
      var customerName:any = res.data.cart.name_local;

      if(typeof customerName === 'string') {
        try {
          customerName = JSON.parse(customerName);
        }
        catch(e) {
          if(process.env.NODE_MODE === 'development') console.warn(e);
          customerName = {en: null, ar: null};
        }
      }
      res.data.cart.name_local = customerName;

      setCart(res.data);
    }
    else {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t("g.ok") }]
      })
    }

    setLoadingCart(false);
    return true;
  }

  const getCustomers = async () => {
    if(loadingCustomers) return null;
    setLoadingCustomers(true);
    setCustomers([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.showAll = true;

    if(!['admin', 'management'].includes(profile.type)) {
      reelaApi.registrarId = profile.id
    }

    var res:any = await reelaApi.getCustomers();

    if(res && res.data) {
      var tempCustomers:any = [];

      res.data.map((customer:any, key:number) => {
        // in buildName function the word 'all' can be used to show customer name in all languages

        var customerName = `${customer.user_id} | ${RLCustomer.buildName('all', customer.name_local)}`

        tempCustomers.push({
          key: customer.user_id,
          text: customerName,
          value: customer.user_id
        })
      });
      setLoadingCustomers(false);

      setCustomers(tempCustomers);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [
        { content: t('g.ok') }
      ]
    })
  }

  const getSetting = async (key:string) => {
    if(!key) return null;
    if(loadingSettings) return null;

    setLoadingSettings(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.key = key;

    var res:any = await reelaApi.getSetting();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSetting(key)
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSetting(key)
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    if(res.status === "success" && res.data) {
      var tempSettings = settings;

      if(!settings.find((setting:any) => setting.uuid === res.data)) {
        tempSettings.push(res.data);
      }

      setSettings(tempSettings);
    }

    setLoadingSettings(false)
    return null;
  }

  const createNewCart = async () => {
    // this function will create a cart for the customer
    if(loadingCustomers) return null

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.customerId = customerId;

    var res:any = await reelaApi.createCart();
    
    if(res && res.data) {
      window.open('/carts/form/' + res.data.uuid, '_self')
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t("g.ok") }]
    })
  }

  const submitOrder = async () => {
    if(completionPrecentage('makeOrder') !== 100) return;
    
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.cartUuid = cartUuid;
    reelaApi.paymentMethod = 'cash';
    reelaApi.deliveryDate = formatDeliveryDate();
    reelaApi.deliveryShift = deliveryShift;

    var res:any = await reelaApi.submitOrder();

    if(res && res.data) {
      if(management) {
        window.open('/orders', '_self');
      }
      else {
        window.open('/carts', '_self');
      }

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t("g.ok") }]
    })
  }

  const deleteCart = async () => {
    
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.cartUuid = cartUuid;

    var res:any = await reelaApi.deleteCart();

    if(res) {
      window.open('/carts', '_self')
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t("g.ok") }]
    })
  }

  const completionPrecentage = (type:'freeShipping'|'makeOrder') => {
    // this function is used to compute the percentage 
    // how close is the sub total to getting free shipping or make the order.
    if(loadingSettings || loadingCart) return 0;
    if(!settings || !cart) return 0;

    if (type === 'freeShipping') {
      const freeShippingMin = Number(RSettings.getSettingInfo('minimum-sub-for-free-shipping', settings)?.value);
      var cartSubTotal = cart?.cart?.sub_total;
      var percentage:number = 0;

      if(cartSubTotal === 0 || cartSubTotal === null){
        return 0;
      }

      if(freeShippingMin === 0 || freeShippingMin === null){
        return 0;
      }

      if(cartSubTotal > freeShippingMin) {
        cartSubTotal = freeShippingMin
      }

      percentage = (cartSubTotal/freeShippingMin) * 100
      return Math.floor(percentage);
    }

    if (type === 'makeOrder') {
      const makeOrderMin = Number(RSettings.getSettingInfo('minimum-sub-for-order', settings)?.value);
      var cartSubTotal = cart?.cart?.sub_total;
      var percentage:number = 0;

      if(cartSubTotal === 0 || cartSubTotal === null){
        return 0;
      }

      if(makeOrderMin === 0 || makeOrderMin === null){
        return 0;
      }

      if(cartSubTotal > makeOrderMin) {
        cartSubTotal = makeOrderMin
      }

      percentage = (cartSubTotal/makeOrderMin) * 100
      return Math.floor(percentage) || 0;
    }
  }

  const formatDeliveryDate = () => {
    if(!deliveryDate) return null;

    //processing the month. 
    const monthDate = deliveryDate?.getMonth().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    const month = String( Number(monthDate)+1 ).padStart(2,'0');

    const day = deliveryDate?.getDate().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});
    const year = deliveryDate?.getFullYear();

    return  `${year}-${month}-${day}`
  }

  const _renderProducts = () => {
    if(products === null || !products) return null;
    if(products.length < 1) return null;

    var render:Array<any> = [];

    products.map( (product:any, key:number) => {

      render.push( 
        <ProductListingRow 
          product={product}
          cart={cart.cart}
          screenLayout={screenLayout}
          getCart={getCart}
          setModal={setModal}
        />
      );
    });

    return render;
  }

  const _renderCartItems = () => {
    if(cart === null || !cart) return null;
    if(cart.items?.length < 1) return null;

    var render:Array<any> = [];

    cart.items?.map( (cartItem:any, key:number) => {

      render.push( 
        <CartItem
          cartItem={cartItem}
          screenLayout={screenLayout}
          getCart={getCart}
          setModal={setModal}
        />
      );
    });

    return render;
  }

  const _renderLoadingRow = (type:boolean) => {
    if(!type) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  if(!management && !registrar) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage loading={loadingProducts && loadingCart}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1' content={t('s.carts.cartDetails')} />
    <Divider hidden />

    {!cartUuid && <>
      <Form loading={loadingCustomers}>
      <Form.Field>
        <Form.Dropdown 
          label={`${t('g.customer')} ${t('g.id')}`}
          placeholder={t('g.selectOne') || 'Select One'}
          fluid
          selection
          search
          loading={loadingCustomers}
          options={customers || undefined}
          onChange={(e,data) => {
            if(typeof data.value === 'number'){
              setCustomerId(data.value)
            }
          }}
        />
      </Form.Field>
      <Form.Field>
        <Form.Button 
          primary
          content={t('g.create')}
          onClick={() => createNewCart()}
        />
      </Form.Field>
    </Form>
    </>}
    {cartUuid && <>
      <Form>
        <Form.Field>
          <Form.Input 
            label={`${t('g.customer')} ${t('g.id')}`}
            placeholder={`${t('g.customer')} ${t('g.id')}`}
            fluid
            value={cart?.cart?.customer_id}
            disabled
          />
        </Form.Field>
        <Form.Group widths='equal'>
          <Form.Field>
            <Form.Input 
              label={`${t('g.name')} (${t('l.english')})`}
              value={cart?.cart?.name_local.en||''}
              placeholder={'Automatically generated'}
              fluid
              disabled
            />
          </Form.Field>
          <Form.Field>
            <Form.Input 
              label={`${t('g.name')} (${t('l.arabic')})`}
              value={cart?.cart?.name_local.ar||''}
              placeholder={'Automatically generated'}
              fluid
              disabled
            />
            </Form.Field>
          </Form.Group>
        <Form.Field>
          <Form.Input 
            label={`${t('s.carts.cart')} ${t('g.id')}`}
            value={cart?.cart?.uuid||''}
            placeholder={'Automatically generated'}
            fluid
            disabled
          />
        </Form.Field>
      </Form>
      <Header as='h1' content={`${t('g.system')} ${t('s.products.title')}`} />
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={13}>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={t('g.search')}
              value={searchTerm}
              onChange={(e, { value }) => {
                if (value.length >= 4) {
                  setSearchTerm(value);
                }
                else {
                  setSearchTerm(null);
                  setProducts([]);
                }
              }}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            <Button
              fluid
              content={t('g.cancel')}
              onClick={() => {
                setSearchTerm("");
                setProducts([]);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {screenLayout === "table" ? 
        <Table color='violet' striped selectable sortable >
          <Table.Header>
            <Table.HeaderCell width={1}>{t('g.id')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('s.products.sku')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('s.products.barcode')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('g.name')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('s.orders.quantity')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('g.control')}</Table.HeaderCell>
          </Table.Header>
          {_renderProducts()}
          {_renderLoadingRow(loadingCart)}
        </Table> 
      :
        _renderProducts()
      }

      <Header as='h1' content={t('s.carts.title')} />
      {screenLayout === "table" ? 
        <Table color='violet' striped selectable sortable >
          <Table.Header>
            <Table.HeaderCell width={1}>{`${t('s.products.product')} ${t('g.id')}`}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t('s.products.sku')}</Table.HeaderCell>
            <Table.HeaderCell width={2}>{t('s.products.barcode')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('g.name')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('s.orders.price')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('s.orders.pit')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('s.orders.t')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('s.orders.tit')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('s.orders.qty')}</Table.HeaderCell>
            <Table.HeaderCell width={1}>{t('g.control')}</Table.HeaderCell>
          </Table.Header>
          {_renderCartItems()}
          {_renderLoadingRow(loadingProducts)} 
        </Table> 
      : 
        _renderCartItems()
      }

      <Progress 
        percent={completionPrecentage('freeShipping')} 
        size='tiny'
        success={completionPrecentage('freeShipping') === 100  ? true : false}
      >
        {completionPrecentage('freeShipping') === 100 ?
          <>
            {t('g.eligibleFor')} {t('g.freeShipping')}
          </>
          :
          <>
          {`
            ${t('g.add')} 
            ${
              displayPrice(Number(
                RSettings.getSettingInfo('minimum-sub-for-free-shipping', settings)?.value
              ) - cart?.cart?.sub_total)
            }  
            ${t('g.get')} ${t('g.freeShipping')}`
          }
          </>
        }
      </Progress>

      <Header as='h1' content={t('s.carts.cartDetails')} />
     
      <Grid>
        <Grid.Row>
          <Grid.Column width={6} verticalAlign='middle'>{t('s.orders.deliveryDate')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            <DatePicker 
              selected={deliveryDate||null} 
              onChange={(date) => {
                setDeliveryDate(date)
              }}
              dateFormat="yyyy-MM-dd"
              showPopperArrow={false}
              minDate={new Date(new Date().setDate(new Date().getDate() + 1))}
              customInput={
                <Input 
                  error={!deliveryDate}
                  placeholder={'2001-09-04'}
                  icon={'calendar alternate outline'}
                />
              }
              withPortal
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.deliveryShift')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            <Dropdown 
              selection
              placeholder={t('g.selectOne') || 'Select One'}
              value={deliveryShift || null}
              options={[
                {key: 0, value: 1,text: t('s.orders.deliveryShifts.morning')},
                {key: 1, value: 2,text: t('s.orders.deliveryShifts.afterNoon')},
                {key: 2, value: 3,text: t('s.orders.deliveryShifts.evening')}
              ]}
              onChange={(e,data) => {
                if(
                  data.value === 1 
                  || data.value === 2 
                  || data.value === 3 
                ){
                  setDeliveryShift(data.value)
                }
              }}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.paymentMethod')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {t('s.orders.paymentMethods.cash')}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={screenLayout === "table" ? 13 : 9} verticalAlign='middle'>{t('s.orders.subTotal')}:</Grid.Column>
          <Grid.Column width={screenLayout === "table" ? 3 : 7} textAlign='right'>
            {displayPrice(cart?.cart?.sub_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.vendorsTax')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(cart?.cart?.vendors_tax_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.shippingTotal')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(cart?.cart?.shipping_cost)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.serviceFee')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(cart?.cart?.service_fee)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.houseTax')} ({cart?.cart?.house_tax_value}):</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(cart?.cart?.house_tax_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.coupon')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {cart?.cart?.coupon || "-"}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.discount')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(cart?.cart?.discount) } {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>{t('s.orders.grandTotal')}:</Grid.Column>
          <Grid.Column width={10} textAlign='right'>
            {displayPrice(cart?.cart?.grand_total)} {t(`c.${localStorage.getItem('currency-key')}`)}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Button 
              primary
              icon='shopping basket'
              content={`${t('g.addNew')} ${t('s.orders.order')}`}
              disabled={!deliveryDate || !deliveryShift || (completionPrecentage("makeOrder") !== 100)}
              onClick={() => {
                submitOrder()
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider  />

      <Segment color='red'>
        <Header color='red'>
          {t('s.carts.deleteCartTitle')}
        </Header>
        
        <div>
          {t('s.carts.deleteCartDescription')}
        </div>
        <Divider hidden/>
        <Button
          negative
          icon='trash'
          content={t('g.delete')}
          type='button'
          onClick={() => setModal({
            visible: true,
            title: t("g.areYouSure"),
            message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
            actions: [
              {
                key: 0,
                content: t("g.cancel"),
                onClick: () => null
              },
              {
                key: 1,
                content: t("g.delete"),
                negative: true,
                onClick: () => deleteCart()
              }
            ]
          }) }
        />
      </Segment>
    </>}
  </InternalPage>
}

export default CartForm;
