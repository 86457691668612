// import UI componented
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'

import { 
  Form, 
  Grid, 
  Header, 
  Button,
  Modal,
  Input,
  Table,
  Loader,
  Menu,
  Icon,
  Dropdown,
  Divider,
  Popup,
  Label
} from 'semantic-ui-react';


// import app config
import { config } from '../config';

// translation
import { useTranslation } from "react-i18next";
import InternalPage from "../framework/internal_page";

import ReeLaAPI from '../util/reela_lib';

export default function ExportForm() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isReady, setIsReady] = useState<boolean>(true);

  const [options, setOptions] = useState<any>({
    exportName: 'orders',
    fromDate: null,
    toDate: null,
    status: null,
    paid: null,
    orderUuid: null,
    customerId: null,
    driverId: null,
    column: 'created_at',
    showDeleted: 'n',
  })

   // states
   interface modalType {
    visible: boolean,
    title: string,
    message: string,
    action: any[]
  }

  const [modal, setModal] = useState<modalType|null>(null);

  const exportFile = async () => {
    setIsReady(false);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem("auth_token");
    reelaApi.lang = i18n.language;
    
    reelaApi.fromDate = options.fromDate;
    reelaApi.toDate = options.toDate;

    reelaApi.showDeleted = options.showDeleted;
    reelaApi.status = options.status;
    reelaApi.paid = options.paid;
    reelaApi.orderUuid = options.orderUuid;
    reelaApi.customerId = options.customerId;
    reelaApi.driverId = options.driverId;
    reelaApi.column = options.column;

    var res:any = null;

    if(options.exportName === 'order_products') {
      res = await reelaApi.exportOrderProducts();
    }
    else {
      res = await reelaApi.exportOrders();
    }

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => exportFile()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => exportFile()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 
  
    else if(res.status === 'success' && res.data) {
      setModal({
        visible: true,
        title: t('g.completed'),
        message: t('g.processCompletedMessage'),
        action: [
          {
            content: t('g.download'),
            onClick: () => window.open(res.data.link, '_self')
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }
    
    setIsReady(true);
    return null;
    
  }

  return (
    <InternalPage >
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal?.action||['Ok']}
      />
      
      <Header
        as='h1'
        content={t('s.export.export')}
      />

      <Form>
        <Form.Field >
          <Form.Dropdown 
            label={`${t('s.export.export')} ${t('g.type')}`}
            selection
            fluid
            clearable
            placeholder={t('g.selectOne') || 'Select One'}
            options={[
              {key: 0, value: 'orders', text: t('s.orders.title')},
              {key: 1, value: 'order_products', text: `${t('s.orders.title')} ${t('s.products.title')}`},
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOptions({
                  ...options,
                  exportName: data.value
                })
              } 
              else {

                setOptions({
                  ...options,
                  exportName: 'orders'
                })
              }
            }}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={`${t('s.orders.order')} ${t('g.uuid')}`}
            placeholder={t('g.typeHere')}
            onChange={(e, data) => setOptions({
              ...options,
              orderUuid: data.value
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={`${t('g.customer')} ${t('g.id')}`}
            placeholder={t('g.typeHere')}
            onChange={(e, data) => setOptions({
              ...options,
              customerId: data.value
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={`${t('g.driver')} ${t('g.id')}`}
            placeholder={t('g.typeHere')}
            onChange={(e, data) => setOptions({
              ...options,
              driverId: data.value
            })}
          />
        </Form.Field>
        {options.exportName === 'orders' && 
          <Form.Field >
            <Form.Dropdown 
              label={t('s.orders.orderBy')}
              selection
              fluid
              clearable
              placeholder={t('g.selectOne') || 'Select One'}
              options={[
                {key: 0, value: 'created_at', text: t('g.createdAt')},
                {key: 1, value: 'delivery_date', text: t('s.orders.deliveryDate')},
              ]}
              onChange={(e, data) => {
                if(data.value && typeof data.value === 'string') {

                  setOptions({
                    ...options,
                    column: data.value
                  })
                } 
                else {

                  setOptions({
                    ...options,
                    column: null
                  })
                }
              }}
            />
          </Form.Field>
        }
        <Form.Field>
          <Popup 
            trigger={
              <Form.Input
                fluid
                label={t('g.from')}
                placeholder={"2001-09-04"}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {

                    setOptions({
                      ...options,
                      fromDate: data.value
                    })
                  } 
                  else {

                    setOptions({
                      ...options,
                      fromDate: null
                    })
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Form.Field>
        <Form.Field>
          <Popup 
            trigger={
              <Form.Input
                fluid
                label={t('g.to')}
                placeholder={"2001-09-04"}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {

                    setOptions({
                      ...options,
                      toDate: data.value
                    })
                  } 
                  else {

                    setOptions({
                      ...options,
                      toDate: null
                    })
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Form.Field>
        <Form.Field >
          <Form.Dropdown 
            label={t('g.paid')}
            selection
            fluid
            clearable
            placeholder={t('g.selectOne') || 'Select One'}
            options={[
              {key: 0, value: 'y', text: t('g.paid')},
              {key: 1, value: 'n', text: t('g.unpaid')},
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOptions({
                  ...options,
                  paid: data.value
                })
              } 
              else {

                setOptions({
                  ...options,
                  paid: null
                })
              }
            }}
          />
        </Form.Field>
        <Form.Field >
          <Form.Dropdown 
            label={t('g.status')}
            selection
            fluid
            clearable
            placeholder={t('g.selectOne') || 'Select One'}
            options={[
              {key: 0, value: 'new', text: t('s.orders.status.new')},
              {key: 1, value: 'preparing', text: t('s.orders.status.preparing')},
              {key: 2, value: 'dispatched', text: t('s.orders.status.dispatched')},
              {key: 3, value: 'in route', text: t('s.orders.status.inRoute')},
              {key: 4, value: 'delivered', text: t('s.orders.status.delivered')},
              {key: 5, value: 'cancelled', text: t('s.orders.status.cancelled')}
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOptions({
                  ...options,
                  status: data.value
                })
              } 
              else {

                setOptions({
                  ...options,
                  status: null
                })
              }
            }}
          />
        </Form.Field>
        <Form.Field >
          <Form.Dropdown 
            label={t('g.showDeleted')}
            selection
            fluid
            clearable
            placeholder={t('g.selectOne') || 'Select One'}
            options={[
              {key: 0, value: 'y', text: t('g.yes')},
              {key: 1, value: 'n', text: t('g.no')},
            ]}
            onChange={(e, data) => {
              if(data.value && typeof data.value === 'string') {

                setOptions({
                  ...options,
                  showDeleted: data.value
                })
              } 
              else {

                setOptions({
                  ...options,
                  showDeleted: null
                })
              }
            }}
          />
        </Form.Field>
      </Form>

      <Header
        as='h1'
        content={t('s.export.fileExtension')}
      />

      <Form>
        <Form.Field>
          <Form.Radio
            label='Microsoft Excel (.xlsx)'
            checked={true}
          />
          <Form.Radio
            label='Comma-separated values (.csv)'
            disabled
          />
          <Form.Radio
            label='Adobe PDF (.pdf)'
            disabled
          />
          <Form.Radio
            label='Microsoft Access (.accdb)'
            disabled
          />
           <Form.Radio
            label='SQL (.sql)'
            disabled
          />
        </Form.Field>
      </Form>

      <Divider hidden />

      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column textAlign="left">
            <Button
              icon='arrow left'
              labelPosition='left'
              content={t('g.cancel')}
              onClick={() => navigate('/')}
            />
          </Grid.Column>
          <Grid.Column floated="right" textAlign="right">
            <Button
              primary
              icon='download'
              labelPosition='right'
              content={t('g.download')}
              loading={!isReady}
              onClick={() => exportFile()}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </InternalPage>
    
  );
}

