
// import config
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { config } from '../config';

import { Button, Divider, Form, Header, Segment, Modal, Grid, Dropdown, GridRow, Label, Message, Icon } from 'semantic-ui-react';

import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';
import ProductPrices from './prices';
import ProductImages from './images';
import ReeLaAPI from '../util/reela_lib';
import ProductsVendorsPrices from './vendors';

import RSettings from "../util/funcs/settings"

const ProductForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  // check URL params
  let { productId } = useParams<any>();

  let profile:any = null;
  let management:boolean = false;
  let vendor:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;

    if(!management) navigate(-1);
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);
  }

  const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(false);
  const [categories, setCategories] = useState<Array<any>|null>([]);

  const [settings, setSettings] = useState<any>(false);
  const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);

  const [isLoadingProduct, setIsLoadingProduct] = useState<boolean>(false);
  const [isProcessinggProduct, setIsProcessingProduct] = useState<boolean>(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState<boolean>(false);

  const [product, setProduct] = useState<any>({
    id: productId || null,
    sku: null,
    category_id: null,
    listing_order: 0,
    is_visible: 'n',
    is_available: 'y',
    name_local: {
      en: null,
      ar: null,
    },
    description_local: {
      en: null,
      ar: null,
    }
  });

  const [productPrices, setProductPrices] = useState<any>(null);
  const [productImages, setProductImages] = useState<Array<any>>([]);
  const [productVendors, setProductVendors] = useState<any>(null);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    if(productId) getProduct();

    getCategories();
    getSettings();
  }, []);


  const getSettings = async () => {
    if(isLoadingSettings) return null
    setIsLoadingSettings(true)

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    var res:any = await reelaApi.getSettings();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');

      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    }

    else if(res.status === "fail"){
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        action: [
          {
            content: t('g.tryAgain'),
            onClick: () => getSettings()
          },
          {
            content: t('g.cancel'),
            onClick: () => null
          },
        ]
      });
    } 

    if(res.status === "success" && res.data) {
      setSettings(res.data);
    }

    setIsLoadingSettings(false)
    return null;
  }

  const getCategories = async () => {
    if(isLoadingCategories) return null;

    setIsLoadingCategories(true);
    setCategories([]);

    // [ ] Call API to get the categories
    var reelaApi:any = new ReeLaAPI;
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.listAll = true // change this to false to get only parent or child-categories

    var res:any = await reelaApi.getCategories();
    setIsLoadingCategories(false);

    if(res && res.data) {
      var organizedCategories:any = [];

      res.data.forEach((category:any, key:number) => {
        var titleLocal = category.title_local;
  
        if(titleLocal && typeof titleLocal === 'string') {
          try { titleLocal = JSON.parse(titleLocal) }
          catch(e) {
            if(process.env.NODE_MODE === 'development') {
              console.error('Failed to parse service city local', category, e);
            }
  
            return null;
          }
        }

        organizedCategories.push({
          key: category.id,
          text: titleLocal['en'],
          value: category.id
        })
      });

      setCategories(organizedCategories)
    }

    return null;
  }

  const getProduct = async () => {
    if(isLoadingProduct) return null;
    setProductImages([]);
    setIsLoadingProduct(true);

    // [ ] Get the product object
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.productId = product.id;

    var res:any = await reelaApi.getProduct();
    setIsLoadingProduct(false);

    if(process.env.NODE_MODE === 'development') console.log('getProduct response:', res);

    if(!res || !res.data || res.data.length < 1) {
      
      // error
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    }

    // handle JSONS
    var receivedProduct = res.data.product;
    var productName = receivedProduct.name_local;
    var productDescription = receivedProduct.description_local;

    if(productName && typeof productName === 'string') {
      try {
        productName = JSON.parse(productName);
        receivedProduct = {...receivedProduct, name_local: productName}
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') 
          console.warn(
            'Failed to parse product name', 
            productName, 
            e
          );
      }
    } 

    if(productDescription && typeof productDescription === 'string') {
      try {
        productDescription = JSON.parse(productDescription);
        receivedProduct = {
          ...receivedProduct, 
          description_local: productDescription
        }
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') 
          console.warn(
            'Failed to parse product description', 
            productDescription, 
            e
          );
      }
    }
    
    setProduct(receivedProduct);
    setProductImages(res.data.images || []);
    setProductPrices(res.data.prices);
    setProductVendors(res.data.vendors);

    return null;
  }

  const processProduct = async () => {
    if(isProcessinggProduct) return null;

    setIsProcessingProduct(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    reelaApi.productId = product.id;
    reelaApi.categoryId = product.category_id;
    reelaApi.sku = product.sku;
    reelaApi.barcode = product.barcode;
    reelaApi.nameLocal = product.name_local;
    reelaApi.descriptionLocal = product.description_local;
    reelaApi.isAvailable = product.is_available;
    reelaApi.isVisible = product.is_visible;
    reelaApi.listingOrder = product.listing_order;

    if(!product.id) {
      var res:any = await reelaApi.createProduct();
    }
    else {
      var res:any = await reelaApi.updateProduct();
    }

    setIsProcessingProduct(false);

    if(process.env.NODE_MODE === 'development') console.log('processProduct response:', res);

    if(!res || res.status !== 'success') {
      
      // error
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }

    else {
      if(!product.id && res.data.id) setProduct({ 
        ...product, id: res.data.id 
      });

      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.ok'), positive: true }]
      })
    }
  }

  const deleteProduct = async () => {

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.productUuid =productId;

    var res:any = await reelaApi.deleteProduct();
    
    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');
    }
    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            key: 0,
            content: t("g.tryAgain"),
            onClick: () => navigate(-1)
          }
        ]
      });
    }
    else if (res.status === "success") {

      navigate('/products')
    }

    return null;
  }

  if(!management && !vendor) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage loading={isLoadingProduct}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1' content={t('s.products.productDetails')} />
    <Header as='h2' content={t('g.details')} />

    <Divider hidden />

    <Form onSubmit={() => processProduct()}>
      <Form.Field>
        <Form.Dropdown 
          label={t('g.availability')}
          placeholder={t('g.selectOne') || 'Select One'}
          fluid
          selection
          value={product.is_available}
          options={[
            {key: 0, value: 'y', text: t('g.available')},
            {key: 1, value: 'n', text: t('g.unavailable')},
          ]}
          onChange={(e, {value}) => setProduct({
            ...product,
            is_available: value
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Dropdown 
          label={t('g.visible')}
          placeholder={t('g.selectOne') || 'Select One'}
          fluid
          selection
          value={product.is_visible}
          options={[
            {key: 0, value: 'y', text: t('g.visible')},
            {key: 1, value: 'n', text: t('g.invisible')},
          ]}
          onChange={(e, {value}) => setProduct({
            ...product,
            is_visible: value
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input 
          label={`${t('s.products.product')} ${t('g.id')}`}
          value={product.id||''}
          placeholder={'Automatically generated'}
          fluid
        />
      </Form.Field>
      <Form.Field>
        <Form.Input 
          label={`${t('g.sku')} #`}
          fluid
          required
          value={product.sku||''}
          placeholder={t('g.typeHere')}
          onChange={(e, {value}) => setProduct({
            ...product,
            sku: value||null
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input 
          label={t('g.barcode')}
          fluid
          required
          value={product.barcode||''}
          placeholder={t('g.typeHere')}
          onChange={(e, {value}) => setProduct({
            ...product,
            barcode: value||null
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Dropdown
          loading={isLoadingCategories}
          clearable
          selection
          label={t('s.categories.category')}
          value={product.category_id}
          placeholder={t('g.selectOne') || 'Select One'}
          onChange={(e, { name, value }) => setProduct({
            ...product,
            category_id: value
          })}
          options={categories||[]}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input 
          label={t('s.products.listingOrder')}
          fluid
          value={product.listing_order}
          placeholder={t('g.typeHere')}
          onChange={(e, {value}) => {
            if(/\d*/.test(value)) {
              setProduct({
                ...product,
                listing_order: value||0
              })
            }
          }}
        />
      </Form.Field>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Input
            required
            label={`${t('g.name')} (${t('l.english')})`}
            fluid
            value={product.name_local?.en || ''}
            onChange={(e, {value}) => setProduct({
              ...product,
              name_local: {
                ...product.name_local,
                en: value||null,
              }
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={`${t('g.name')} (${t('l.arabic')})`}
            fluid
            value={product.name_local?.ar || ''}
            onChange={(e, {value}) => setProduct({
              ...product,
              name_local: {
                ...product.name_local,
                ar: value||null
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.TextArea
            required
            label={`${t('g.descriptionLocal')} (${t('l.english')})`}
            fluid
            value={product.description_local?.en || ''}
            onChange={(e, {value}) => setProduct({
              ...product,
              description_local: {
                ...product.description_local,
                en: value||null
              }
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.TextArea
            label={`${t('g.descriptionLocal')} (${t('l.arabic')})`}
            fluid
            value={product.description_local?.ar || ''}
            onChange={(e, {value}) => setProduct({
              ...product,
              description_local: {
                ...product.description_local,
                ar: value||null
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Button
        primary
        icon='save'
        content={t('g.update')}
      />
    </Form>

    <ProductImages 
      productId={product.id} 
      images={productImages} 
      defaultImageId={product.default_image_id}
      management={management} 
    />

    <Divider hidden/>

    <ProductPrices 
      directPricingWarning={() => {
        var pricingModule = RSettings.getSettingInfo('pricing-module', settings)?.value;
        if(pricingModule === 'direct') return true;
        else return false;
      }}
      productId={product.id} 
      prices={productPrices}
      management={management}
    />

    <ProductsVendorsPrices 
      productId={product.id}
      vendors={productVendors}
    />

    {/* component to show vendors prices */}

    {(management && product.id) && <>
      <Divider hidden />
      <Header as='h2' content={t('g.administration')} />

      <Segment color='red' loading={isLoadingProduct}>
        <Header color='red'>
          {t('s.products.deleteProductTitle')}
        </Header>
        
        <div>
          {t('s.products.deleteProductDescription')}
        </div>
        
        <Divider hidden />

        <Button
          negative
          icon='trash'
          content={t('g.delete')}
          type='button'
          onClick={() => setModal({
            visible: true,
            title: t("g.areYouSure"),
            message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
            actions: [
              {
                key: 0,
                content: t("g.cancel"),
                onClick: () => null
              },
              {
                key: 1,
                content: t("g.delete"),
                negative: true,
                onClick: () => deleteProduct()
              }
            ]
          }) }
        />
      </Segment>
    </>}
  </InternalPage>
}

export default ProductForm;
