import moment from 'moment';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

// import UI components
import { Button, Dropdown, Grid, Header, Icon, Input, Loader, Menu, Table } from 'semantic-ui-react';

// styling
import '../framework/css/general.css';

// import config
import { config } from '../config';

// import ReeLa lib
import ReeLaAPI from '../util/reela_lib';

// import components
import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';
import { useTranslation } from 'react-i18next';

const UsersListing = (props:any) => {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();

  var authorized = false;
  var profile:any = localStorage.getItem('profile');
  var allowedAccess = ['management']

  if(profile) {
    try {
      profile = JSON.parse(profile);

      if(
        profile && 
        allowedAccess.includes(profile.type) &&
        (
          profile.type_details.isAdming ||
          profile.type_details.access_users  
        )
      ) {
        authorized = true;
      }
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') console.error(e);
    }
  }

  // component states
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [type, setType] = useState<any>(null);
  const [users, setUsers] = useState<any>(null);

  //pagination states
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  // component did mount
  useEffect(() => {
    getUsers();
  }, [])

  // run getPost after searchTerm update
  useEffect( () => {
    getUsers();
  }, [searchTerm, resPerPage, currentPage, type]);

  const getUsers = async () => {
    setLoadingUsers(true);
    setUsers([]);

    //
    var reelaApi:any = new ReeLaAPI;

    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.currentPage = currentPage;
    reelaApi.resPerPage = resPerPage;
    reelaApi.searchTerm = searchTerm;
    reelaApi.type = type;

    var res = await reelaApi.getUsers();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error processing');

      setLoadingUsers(false);
      return null;
    }

    if(res.status === 'success') {
      setUsers(res.data);
    }
    else {
      if(process.env.NODE_MODE === 'development') console.error('Failed to process');
    }

    // complete the function
    setLoadingUsers(false);
    return true;
  }

  const renderUsers = () => {
    if(users == null) return null;
    
    // return no results found
    if(users.length < 1) {
      return (
        <Table.Row>
          <Table.Cell colSpan={7} verticalAlign='middle'>
            {t('g.noResults')}
          </Table.Cell>
        </Table.Row>
      );
    }

    var render:any[] = [];

    users.forEach( (user:any, key:number) => {
      //
      var id = user.id;
      var openRecord = () => navigate(`/users/show/${user.id}`)

      render.push(
        <Table.Row key={key}>
          <Table.Cell onClick={openRecord}>
            {id}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {user.first_name} {user.last_name}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {user.email}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {user.country_code} - {user.phone}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {(user.blocked === 'y') ? t("g.yes") : t("g.no")}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {t(`g.${user.type}`)}
          </Table.Cell>
          <Table.Cell onClick={openRecord}>
            {moment(user.created_at).fromNow()}
          </Table.Cell>
        </Table.Row>
      )
    });

    return render
  }

  const renderLoadingRow = () => {
    if(!loadingUsers) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  if(!authorized) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <>
    <InternalPage>
      <Header as='h1'>
        {t('s.users.title')}
        <Header.Subheader>{t('s.users.listingDescription')}</Header.Subheader>
      </Header>

      <Grid stackable columns={16}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Button
              icon='plus'
              content={t('g.addNew')}
              primary
              onClick={() => navigate('/users/form')}
            />
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            <Dropdown
              fluid
              selection
              clearable
              placeholder={t('s.profile.accountType') || 'accountType'}
              onChange={(e, {name, value}) => setType(value)}
              options={[
                {key: 0, text: t('s.profile.management'), value: 'management'},
                {key: 1, text: t('s.profile.vendor'), value: 'vendor'},
                {key: 2, text: t('s.profile.customer'), value: 'customer'},
                {key: 3, text: t('s.profile.driver'), value: 'driver'},
                {key: 4, text: t('s.profile.registrar'), value: 'registrar'}
              ]}
            />
          </Grid.Column>
          <Grid.Column width={4} textAlign='right'>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={t('g.search')}
              value={searchTerm}
              onChange={(e, { value }) => setSearchTerm(value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table color='violet' striped selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t('s.profile.fullName')}</Table.HeaderCell>
            <Table.HeaderCell>{t('g.email')}</Table.HeaderCell>
            <Table.HeaderCell>{t('g.phone')}</Table.HeaderCell>
            <Table.HeaderCell>{t('g.blocked')}</Table.HeaderCell>
            <Table.HeaderCell>{t('g.type')}</Table.HeaderCell>
            <Table.HeaderCell>{t('s.profile.joinDate')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {renderUsers()}
          {renderLoadingRow()}
        </Table.Body>
      </Table>

      <Menu secondary>
        <Menu.Item
          name='previous'
          onClick={() => {
            if(currentPage === 1) return null;
            else setCurrentPage(currentPage - 1)
          }}
        >
          <Icon name='angle left' />
        </Menu.Item>
        <Menu.Item
          name='page number'
        >
          <Input
            value={currentPage}
            onChange={(e, { value }) => setCurrentPage(parseInt(value))}
          />
        </Menu.Item>
        <Menu.Item
          name='next'
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          <Icon name='angle right' />
        </Menu.Item>
        <Menu.Menu position='right'>
          <span>
            <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
            <Dropdown
              inline
              onChange={(e, data) => setResPerPage(Number(data.value))}
              placeholder={resPerPage.toString()}
              options={[
                { key: 15, text: 15, value: 15 },
                { key: 30, text: 30, value: 30 },
                { key: 60, text: 60, value: 60 },
                { key: 200, text: 200, value: 200 },
              ]}
            />
          </span>
        </Menu.Menu>
      </Menu>
    </InternalPage>
  </>
}

export default UsersListing;