// import UI componented
import { useTranslation } from 'react-i18next';
import { Grid, Loader, Modal } from 'semantic-ui-react';

// import components
import SiteHeader from './site_header';
import SideMenu from './side_menu';

export default function InternalPage(props:any) {
  const {t, i18n} = useTranslation();

  return <>
    <Grid padded stackable>
      <Grid.Row>
        <Grid.Column width={16}>
          <SiteHeader />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row colSpan='2'>
        <Grid.Column computer={3} only='computer'>
          <SideMenu />
        </Grid.Column>
        <Grid.Column computer={13} tablet={16} mobile={16} style={{paddingBottom: '50px'}}>
          {props.loading 
            ? <Loader active content={t('g.loading')} /> 
            : props.children
          }
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
}

