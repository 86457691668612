
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  Menu, 
  Grid, 
  Table, 
  Input, 
  Header, 
  Loader, 
  Icon, 
  Dropdown,
  Modal, 
  Form, 
  Container,
  Segment,
  Divider,
  Popup
} from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import ReeLaAPI from '../util/reela_lib';
import moment from "moment";
import { formatDateTime, displayPrice, formatDate, URLQuery } from '../util/tools';
import RLServiceCity from '../util/funcs/service_cities';

// import config
import { config } from '../config';

const CartsListing = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loadingCarts, setLoadingCarts] = useState<boolean>(false);
  const [carts, setCarts] = useState<Array<any>|null>(null);
  
  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);

  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [registrarId, setRegistrarId] = useState<string|null>(null);
  const [customerId, setCustomerId] = useState<string|null>(null);
  const [fromDate, setFromDate] = useState<string|null>(null);
  const [toDate, setToDate] = useState<string|null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string|null>(null);
  const [orderDirection, setOrderDirection] = useState<string|null>(null);
  const [tableOrderDirection, setTableOrderDirection] = useState<any>(null);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [isLoadingServiceCities, setIsLoadingServiceCities] = useState<boolean>(false);
  const [serviceCities, setServiceCities] = useState<any>(null);

  const [modal, setModal] = useState<any>();

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  // set lets
  let profile:any = null;
  let management:boolean = false;
  let registrar:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;
    if(profile.type === 'registrar') registrar = true;
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);
  }

  useEffect(() => {
    // set default view
    if(!screenLayout) {
      if(window.innerWidth <= 768) setScreenLayout('cards');
      else setScreenLayout('table');
    }
    
    getCarts();
    getServiceCities();
    setOrderBy(null);
    setOrderDirection('ASC');
  }, []);

  // run getPost after searchTerm update
  useEffect( () => {
    getCarts();
    getServiceCities();
    
    if(orderDirection === 'ASC') setTableOrderDirection('ascending')
    else setTableOrderDirection('descending');
  }, [
    searchTerm, 
    resPerPage, 
    currentPage, 
    orderBy, 
    orderDirection, 
    fromDate, 
    toDate,
    registrarId,
    customerId
  ]);

  const getCarts = async () => {
    // get the carts list
    if(loadingCarts) return null
    setLoadingCarts(true);
    setCarts([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.searchTerm = searchTerm;
    reelaApi.registrarId = registrarId;
    reelaApi.customerId = customerId;
    reelaApi.fromDate = fromDate;
    reelaApi.toDate = toDate;
    reelaApi.page = currentPage;
    reelaApi.resPerPage = resPerPage;
    reelaApi.orderBy = orderBy;
    reelaApi.orderDirection = orderDirection;

    var res:any = await reelaApi.getCarts();

    setLoadingCarts(false);

    if(res && res.data) {
      setCarts(res.data.carts);      
    }

    return null;
  }

  const getServiceCities = async () => {
    if(isLoadingServiceCities) return null;

    setIsLoadingServiceCities(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    
    var res:any = await reelaApi.getServiceCities();
    setIsLoadingServiceCities(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    } 

    var organizedServiceCities:any = [];

    res.data.forEach((sc:any, key:number) => {
      organizedServiceCities.push({
        key: sc.id,
        text: RLServiceCity.buildLabel('en', sc),
        value: sc.id
      })
    });

    setServiceCities(organizedServiceCities);
    return null;
  }

  const changeSort = (columnName:any) => {
    setOrderBy(columnName);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC': 'ASC');
  }

  const _renderCarts = () => {
    // render the table body
    if(carts === null) return null;
    if(carts.length < 1) return null;

    var render:Array<any> = [];
    carts.map( (cart:any, key) => {
      
      var customerName:any = cart.name_local;

      if(typeof customerName === 'string') {
        try {
          customerName = JSON.parse(customerName);
        }
        catch(e) {
          if(process.env.NODE_MODE === 'development') console.warn(e);
          customerName = {en: null, ar: null};
        }
      }

      var printedCustomerName = [];

      for(const [key, value] of Object.entries(customerName)) {
        printedCustomerName.push(<div>{String(value)}</div>);
      }
    
      if(typeof customerName === 'object') {
        customerName = <div>
          {printedCustomerName}
        </div>
      }

      if(screenLayout === 'table') {
        render.push(
          <Table.Row 
            key={key} 
            onClick={() => navigate('/carts/form/' + cart.uuid)}
          >
            <Table.Cell>
              #{cart.uuid.substring(cart.uuid.length-10)}
            </Table.Cell>
            <Table.Cell>
              {cart.registrar_id}
            </Table.Cell>
            <Table.Cell>
              {cart.customer_id}
            </Table.Cell>
            <Table.Cell>
              {customerName}
            </Table.Cell>
            <Table.Cell>
              {serviceCities?.find((serviceCity:any) => serviceCity.value === cart.service_city_id)?.text}
            </Table.Cell>
            <Table.Cell>
              {displayPrice(cart.sub_total)}
            </Table.Cell>
            <Table.Cell>
              {cart.house_tax_total}
            </Table.Cell>
            <Table.Cell>
              {cart.shipping_cost}
            </Table.Cell>
            <Table.Cell>
              {displayPrice(cart.grand_total)}
            </Table.Cell>
            <Table.Cell>
              <div>{formatDateTime(cart.created_at)}</div>
            </Table.Cell>
          </Table.Row>
        );
      }

      else {
        render.push(<>
          <Divider hidden />
          <Segment 
            color="blue"
            onClick={() => navigate('/carts/form/' + cart.uuid)}
          >
            <Grid columns={2}>
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div style={{fontWeight: 'bold'}}>
                    {t('g.uuid')}: #{cart.uuid.substring(cart.uuid.length-10)}
                  </div>
                  <div>
                    {t('g.id')}: {cart.customer_id}
                  </div>
                  <div>
                    {t('g.name')}: {customerName}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('s.orders.subTotal')}</strong></div>
                  <div>{displayPrice(cart.sub_total)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div><strong>{t('g.createdAt')}</strong></div>
                  <div>
                    {cart.created_at &&
                    moment(cart.created_at).format(config.momentDateTimeFormat) }
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('s.orders.houseTax')}</strong></div>
                  <div>{cart.house_tax_total}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width='equal'>
              <Grid.Column>
                  <div><strong>{t('g.registrar')}</strong></div>
                  <div>{cart.registrar_id}</div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('g.shipping')}</strong></div>
                  <div>{displayPrice(cart.shipping_cost)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row width='equal'>
                <Grid.Column>
                  <div><strong>{t('s.serviceCities.serviceCity')}</strong></div>
                  <div>{
                    serviceCities?.find((serviceCity:any) => serviceCity.value === cart.service_city_id)?.text}
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div><strong>{t('s.orders.grandTotal')}</strong></div>
                  <div>{displayPrice(cart.grand_total)}</div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </>)
      }
    });

    return <>{render}</>
  }

  const _renderLoadingRow = () => {
    if(!loadingCarts) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {t('s.carts.title')}
      <Header.Subheader>{t('s.carts.listingDescription')}</Header.Subheader>
    </Header>

    <Grid stackable>
      <Grid.Row width={16} only='mobile tablet'>
        <Grid.Column>
          <Button
            fluid
            secondary
            content={t('g.menu')}
            onClick={() => setToggleMenu(!toggleMenu)}
          />
        </Grid.Column>
      </Grid.Row >
      <Grid.Row columns={3}>
        <Grid.Column>
          <Input
            fluid
            icon={{ name: 'search', link: true }}
            placeholder={t('g.search')}
            value={searchTerm}
            onChange={(e, { value }) => {
              setSearchTerm(value)
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Popup 
            trigger={
              <Input
                fluid
                icon={'search'}
                placeholder={t('g.from')}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                    setFromDate(data.value)
                  } else {
                    setFromDate(null)
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Grid.Column>
        <Grid.Column>
          <Popup 
            trigger={
              <Input
                fluid
                icon={'search'}
                placeholder={t('g.to')}
                onChange={(e,data) => {
                  if (/\d{4}-\d{2}-\d{2}/.test(data.value)) {
                    setToDate(data.value)
                  } else {
                    setToDate(null)
                  }
                }}
              />
            }
            header='input format'
            content='example: 2001-09-04'
            on={'hover'}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={3} only={!toggleMenu ? 'computer widescreen large screen' : 'mobile tablet'}>
        <Grid.Column>
          <Input
            fluid
            icon={{ name: 'search', link: true }}
            placeholder={t('g.customer')}
            value={customerId}
            onChange={(e, { value }) => {
              setCustomerId(value)
            }}
          />
        </Grid.Column>
        {management && <>
          <Grid.Column>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={t('g.registrar')}
              value={registrarId}
              onChange={(e, { value }) => {
                setRegistrarId(value)
              }}
            />
          </Grid.Column>
        </>}
      </Grid.Row>
    </Grid>

    <Grid stackable columns={16}>
      <Grid.Column width={6}>
      </Grid.Column>
      <Grid.Column width={6}>
        <Menu secondary position='right'>
        <Menu.Menu >
          <Menu.Item
            icon='refresh'
            name={t('g.refresh') || 'Refresh'}
            onClick={() => getCarts()}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='table'
            name={t('g.tableView') || 'Table View'}
            onClick={() => setScreenLayout('table')}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='list layout'
            name={t('g.listView') || 'List View'}
            onClick={() => setScreenLayout('cards')}
          />
        </Menu.Menu>
        </Menu>
      </Grid.Column>
    </Grid>

    {screenLayout === 'table' &&
    <Table color='violet' striped selectable sortable >
      <Table.Header>
        <Table.HeaderCell width={2}>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('g.registrar')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('g.customer')} {t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('g.customer')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('s.serviceCities.serviceCity')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.subTotal')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.houseTax')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.shippingTotal')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.grandTotal')}</Table.HeaderCell>
        <Table.HeaderCell 
          width={2}
          onClick={() => changeSort('created_at')}
          sorted={orderBy === 'created_at' ? tableOrderDirection : null}
        >{t('g.createdAt')}</Table.HeaderCell>
      </Table.Header>
      {_renderCarts()}
      {_renderLoadingRow()}
    </Table> }
    
    {screenLayout === 'cards' &&
    <div>
      {_renderCarts()}
    </div> }
    
    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>
}

export default CartsListing;