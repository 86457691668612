import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { 
  Button,
  Input,
  Table,
  Dropdown,
  Modal
} from "semantic-ui-react";
import { config } from "../config";


const ProductVendorPriceRow = (props:any) => {
  const {t, i18n} = useTranslation();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [price, setPrice] = useState<number|string>(props.vendor.price || 0);
  const [priceIncTax, setPriceIncTax] = useState<number|string>(0);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    // set the price inc tax
    if(
      !priceIncTax && 
      props.vendor.price && 
      props.vendor.tax
    ) {
      setPriceIncTax(props.vendor.price + (props.vendor.price * props.vendor.tax));
    }
  })

  const updateVendorPrice = async () => {
    alert('Process updating product price')
    // validate the input before submitting
    // >

    if(isProcessing) return null;
    setIsProcessing(true);

    // [ ] Process adding
    
    setIsProcessing(false);

    // [ ] Handle response
    // [ ] On faile setModal for error
    // [ ] On success switch editMode

    return null;
  }

  const deleteVendorPrice = async () => {
    alert('Process deleting product price')
    // validate the input before submitting
    // >

    if(isProcessing) return null;
    setIsProcessing(true);

    // [ ] Process adding
    
    setIsProcessing(false);

    // [ ] Handle response
    // [ ] On faile setModal for error
    // [ ] On success push retrieved record to props.prices
    // [ ] On success setHideRow(true)

    return null;
  }

  const _renderVendorName = () => {
    if(
      !props.vendor ||
      !props.vendor.name_local
    ) {
      return '/** Error';
    }

    var nameLocal = props.vendor.name_local;

    if(typeof nameLocal === 'string') {
      try {
        nameLocal = JSON.parse(nameLocal);
        nameLocal = nameLocal[localStorage.getItem('language') || 'en']
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') console.warn('Failed to parse vendor nameLocal', nameLocal, e);
        nameLocal = '/** Error';
      }
    }
      
    return nameLocal;
  }

  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Table.Row>
      <Table.Cell>
        {props.vendor.id}
      </Table.Cell>
      <Table.Cell>
        {_renderVendorName()}
      </Table.Cell>
      <Table.Cell>
        {props.vendor.city}, {props.vendor.state}, {props.vendor.country}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Input
          required
          placeholder='0.00'
          label={t(`c.${localStorage.getItem('currency-key')}`)}
          labelPosition="right"
          value={price.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setPrice(value)
            setPriceIncTax((Number(value) + (Number(value) * 0.15)))
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Input
          required
          placeholder='0.00'
          label={t(`c.${localStorage.getItem('currency-key')}`)}
          labelPosition="right"
          value={priceIncTax.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setPrice((Number(value) / (1.15)).toFixed(2))
            setPriceIncTax(value)
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          icon='save'
          primary
          size='tiny'
          content={t('g.update')}
          onClick={() => updateVendorPrice()}
        />
        <Button
          icon='trash'
          negative
          size='tiny'
          onClick={() => {
            // setModal for confirmation
            // >
            deleteVendorPrice()
          }}
        />
      </Table.Cell>
    </Table.Row>
  </>
}

export default ProductVendorPriceRow;