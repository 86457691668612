
// import config
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { config } from '../config';

import { Button, Divider, Form, Header, Segment, Modal, Grid, Dropdown, GridRow, Label } from 'semantic-ui-react';

import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';
// import ProductPrices from './prices';
// import ProductImages from './images';
import ReeLaAPI from '../util/reela_lib';
// import ProductsVendorsPrices from './vendors';

const CategoryForm = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  // check URL params
  let { categoryId } = useParams<any>();

  let profile:any = null;
  let management:boolean = false;
  let vendor:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;

    if(!management) navigate(-1);
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);
  }

  const [isLoadingCategories, setIsLoadingCategories] = useState<boolean>(false);
  const [categories, setCategories] = useState<Array<any>|null>([]);

  const [isLoadingCategory, setIsLoadingCategory] = useState<boolean>(false);
  const [isProcessinggProduct, setIsProcessingProduct] = useState<boolean>(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState<boolean>(false);

  const [category, setCategory] = useState<any>({
    id: categoryId || null,
    order: null,
    parent_id: null,
    active: null,
    title_local: {
      en: null,
      ar: null,
    },
  });

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    if(categoryId) getCategory();
    getCategories();
  }, []);

  const getCategory = async () => {
    if(isLoadingCategory) return null;
    setIsLoadingCategory(true);

    // [ ] Get the category object
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.categoryId = categoryId;

    var res:any = await reelaApi.getCategory();
    
    setIsLoadingCategory(false);

    if(process.env.NODE_MODE === 'development') console.log('getCategory response:', res);

    if(!res || !res.data || res.data.length < 1) {
      
      // error
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    }

    // handle JSONS
    var receivedCategory = res.data;
    var categoryName = receivedCategory.title_local;

    if(categoryName && typeof categoryName === 'string') {
      try {
        categoryName = JSON.parse(categoryName);
        receivedCategory = {
          ...receivedCategory, 
          title_local: categoryName
        }
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') 
          console.warn(
            'Failed to parse product name', 
            categoryName, 
            e
          );
      }
    } 
    
    setCategory(receivedCategory);

    return null;
  }

  const getCategories = async () => {
    if(isLoadingCategories) return null;

    setIsLoadingCategories(true);
    setCategories([]);

    // [ ] Call API to get the categories
    var reelaApi:any = new ReeLaAPI;
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.listAll = true

    var res:any = await reelaApi.getCategories();
    setIsLoadingCategories(false);

    if(res && res.data) {
      var organizedCategories:any = [{}];

      res.data.forEach((category:any, key:number) => {
        var titleLocal = category.title_local;
  
        if(titleLocal && typeof titleLocal === 'string') {
          try { titleLocal = JSON.parse(titleLocal) }
          catch(e) {
            if(process.env.NODE_MODE === 'development') {
              console.error('Failed to parse service city local', category, e);
            }
  
            return null;
          }
        }

        organizedCategories.push({
          key: category.id,
          text: titleLocal['en'],
          value: category.id
        })
      });

      setCategories(organizedCategories)
    }

    return null;
  }

  const processCategory = async () => {
    if(isLoadingCategory) return null;

    setIsLoadingCategory(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    reelaApi.categoryId = category.id;
    reelaApi.parentId = category.parent_id;
    reelaApi.titleLocal = category.title_local;
    reelaApi.active = category.active;
    reelaApi.order = category.order;

    var res:any = null;

    if(!category.id) {
      res = await reelaApi.createCategory();
    }
    else {
      res = await reelaApi.updateCategory();
    }

    setIsLoadingCategory(false);

    if(process.env.NODE_MODE === 'development') console.log('processCategory response:', res);

    if(!res || res.status !== 'success') {
      
      // error
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }

    else {
      if(!category.id && res.data.id) setCategory({ 
        ...category, id: res.data.id 
      });

      setModal({
        visible: true,
        title: t("g.processCompleted"),
        message: t("g.processCompletedMessage"),
        actions: [{ content: t('g.ok'), positive: true }]
      })
    }
  }

  const deleteCategory = async () => {

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.categoryId = category.id;

    var res:any = await reelaApi.deleteCategory();
    
    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');
    }
    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            key: 0,
            content: t("g.tryAgain"),
            onClick: () => navigate(-1)
          }
        ]
      });
    }
    else if (res.status === "success") {

      navigate('/categories');
    }

    return null;
  }

  if(!management) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage loading={isLoadingCategory}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1' content={t('s.categories.categoryDetails')} />
    <Divider hidden />

    <Form>
      {categoryId ? 
        <Form.Field>
          <Form.Dropdown 
            label={t('g.active')}
            placeholder={t('g.selectOne') || 'Select One'}
            fluid
            selection
            value={category.active}
            options={[
              {key: 0, value: 'y', text: t('g.active')},
              {key: 1, value: 'n', text: t('g.inactive')},
            ]}
            onChange={(e, {value}) => setCategory({
              ...category,
              active: value
            })}
          />
        </Form.Field>
      :
        null  
      }
      <Form.Field>
        <Form.Input 
          label={`${t('s.categories.category')} ${t('g.id')}`}
          value={category.id||''}
          placeholder={'Automatically generated'}
          fluid
        />
      </Form.Field>
      <Form.Field>
        <Form.Input 
          label={t('s.categories.listingOrder')}
          fluid
          value={category.order||0}
          placeholder={t('g.typeHere')}
          onChange={(e, {value}) => setCategory({
            ...category,
            order: value||0
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Dropdown
          loading={isLoadingCategories}
          clearable
          selection
          label={`${t("s.categories.parent")} ${t("g.id")}`}
          value={category.parent_id}
          placeholder={t('g.selectOne') || 'Select One'}
          onChange={(e, { name, value }) => setCategory({
            ...category,
            parent_id: value || 0
          })}
          options={categories||[]}
        />
      </Form.Field>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Input
            required
            label={`${t('g.name')} (${t('l.english')})`}
            fluid
            value={category.title_local?.en || ''}
            onChange={(e, {value}) => setCategory({
              ...category,
              title_local: {
                ...category.title_local,
                en: value||null,
              }
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            label={`${t('g.name')} (${t('l.arabic')})`}
            fluid
            value={category.title_local?.ar || ''}
            onChange={(e, {value}) => setCategory({
              ...category,
              title_local: {
                ...category.title_local,
                ar: value||null,
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Button
        primary
        icon='save'
        content={t('g.update')}
        onClick={() => processCategory()}
      />
    </Form>

    {(management && category.id) && <>
      <Divider hidden />
      <Header as='h2' content={t('g.administration')} />

      <Segment color='red' loading={isLoadingCategory}>
        <Header color='red'>
          {t('s.categories.deleteCategoryTitle')}
        </Header>
        
        <div>
          {t('s.categories.deleteCategoryDescription')}
        </div>
        
        <Divider hidden />

        <Button
          negative
          icon='trash'
          content={t('g.delete')}
          type='button'
          onClick={() => setModal({
            visible: true,
            title: t("g.areYouSure"),
            message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
            actions: [
              {
                key: 0,
                content: t("g.cancel"),
                onClick: () => null
              },
              {
                key: 1,
                content: t("g.delete"),
                negative: true,
                onClick: () => deleteCategory()
              }
            ]
          }) }
        />
      </Segment>
    </>}
  </InternalPage>
}

export default CategoryForm;