import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// import UI components
import { 
  Button, 
  Image, 
  Grid,
  Header, 
  Icon,
  Loader, 
  Label, 
  Modal, 
  Divider, 
  Input,
  Segment
 } from 'semantic-ui-react';
 
// import config
import { config } from '../config';

// import ReeLa lib
import ReeLaAPI from '../util/reela_lib';

// import components
import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

const UserDetails = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [loadingProfile, setLoadingProfile] = useState<boolean>(false);
  const [updatingProfileImage, setUpdatingProfileImage] = useState<boolean>(false);
  const [deletingProfileImage, setDelettingProfileImage] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [profileImagePath, setProfileImagePath] = useState<string|null>(null);
  const [profile, setProfile] = useState<any>(null);

  const [modal, setModal] = useState<any>(null);

  // set the params
  let { profileId } = useParams<any>(); 

  let authorized:boolean = false;
  let user:any = localStorage.getItem('profile');
  let allowedAccess:Array<string> = ['management', 'owner'];

  if(user && profile) {
    try {
      user = JSON.parse(user);

      // check if the user is the owner
      if(
        allowedAccess.includes('owner') &&
        profile.id === user.id
      ) {
        authorized = true
      }

      else if(
        user && 
        allowedAccess.includes(user.type) &&
        (
          user.type_details.isAdming ||
          user.type_details.access_users  
        )
      ) {
        authorized = true;
      }
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') console.error(e);
    }
  }

  useEffect( () => {
    getProfile();
  }, []);

  useEffect(() => { updateProfileImage() }, [selectedImage]);

  const getProfile = async () => {
    if(loadingProfile) return null;

    setLoadingProfile(true);
    setProfile(null);

    var reelaApi:any = new ReeLaAPI;

    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.profileId = profileId;

    var res:any = await reelaApi.getProfile();

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error processing');

      setLoadingProfile(false);
      return null;
    }

    if(res.status === 'success') {
      var retrievedProfile = res.data;
  
      // set the profile image
      var baseAPI = localStorage.getItem('domain_target');
      var profileImagePath = `${baseAPI}images/profiles/${retrievedProfile.id}.jpg`;
  
      setProfileImagePath(profileImagePath);
      setProfile(retrievedProfile);
    }
    else {
      if(process.env.NODE_MODE === 'development') console.error('Failed to process');
    }

    // complete the function
    setLoadingProfile(false);
    return true;
  }

  const updateProfileImage = async () => {
    if(updatingProfileImage || !selectedImage) return null;
    setUpdatingProfileImage(true);

    const data:any = new FormData() 
    data.append("profile_image", selectedImage);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.profileId = profileId;
    reelaApi.body = data;
    reelaApi.useAppend = true;
    
    var res:any = await reelaApi.uploadProfileImage();
    setUpdatingProfileImage(false);

    if(!res || res.status === 'fail') {
      if(process.env.NODE_MODE === 'development') console.warn('No results from server', res);

      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: t("g.failedToProcessRequestMessage"),
        actions: [{ content: t('g.ok') }]
      });
    }
    else {
      window.location.reload();
    }

    return null;
  }

  const removeProfileImage = async () => {
    if(deletingProfileImage) return null;
    setDelettingProfileImage(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.profileId = profile.id;
    reelaApi.removeProfileImage = true;

    var res:any = await reelaApi.updateProfile();

    if(res && res.status === 'success') {
      setDelettingProfileImage(false);
      window.location.reload();
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage")
    });

    // complete the function
    setDelettingProfileImage(false);
    return true;
  }

  var logout = async() => {
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.allDevices = true;

    var res:any = await reelaApi.logout();

    if(!res) {
      // show error
      return null;
    }

    // clear all the neccessary stored values
    localStorage.removeItem('auth_token');
    localStorage.removeItem('device_uuid');
    localStorage.removeItem('profile');

    // redirect to login screen
    navigate('/login');
  }

  const deleteProfile = async () => {

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.profileUuid = profileId;

    var res:any = await reelaApi.deleteProfile();
    
    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');
    }
    else if (res.status === "fail") {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            key: 0,
            content: t("g.tryAgain"),
            onClick: () => navigate(-1)
          }
        ]
      });
    }
    else if (res.status === "success") {
      navigate('/users')
    }

    return null;
  }

  if(!profile) {
    return <InternalPage>
      <Loader active inline='centered' />
    </InternalPage>
  }

  if(!authorized) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  // action buttons
  var actionBtns = [
    {
      permission: ['owner', 'management'],
      apply: ['all'],
      label: t('g.edit'),
      onClick: () => navigate(`/users/form/${profile.id}`)
    },
    {
      permission: ['owner', 'management'],
      apply: ['business'],
      label: t('s.businesses.business'),
      onClick: () => navigate(`/businesses/show/${profile.business_uuid}`)
    },
    {
      permission: ['owner', 'management'],
      apply: ['user'],
      label: t('s.products.title'),
      onClick: () => navigate('/orders/?searchTerm=' + profile.id)
    },
    {
      permission: ['owner', 'management'],
      apply: ['all'],
      label: t('s.auth.logoutAllDevices'),
      onClick: logout
    }
  ];

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <div>
      {actionBtns.map((btn:any, key:number) => {
        if(!btn.permission.includes(user.type)) return null;

        if(
          !btn.apply.includes(profile.type) && 
          !btn.apply.includes('all')
        ) return null;

        return <Button
          key={key}
          content={btn.label}
          onClick={() => btn.onClick()}
        />
      })}
    </div>

    <Divider hidden />

    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column width={4}>
          <Image 
            src={profileImagePath}
            fluid
            rounded
          />

          <Input
            type='file'
            accept='image/jpeg'
            onChange={(event, e) => {
              if(event && event.target && event.target.files) {
                var file:any = event.target.files[0];
                setSelectedImage(file);
              }
            }} 
            style={{display: 'none'}}
            id='image-upload-selector'
          />

          <div style={{marginTop: 10}}>
            <Button
              size='mini'
              icon='upload'
              content={t('g.change')}
              loading={updatingProfileImage}
              onClick={() => {
                document.getElementById('image-upload-selector')?.click()
              }}
            />
            <Button
              size='mini'
              icon='trash'
              content={t('g.delete')}
              loading={deletingProfileImage}
              negative
              onClick={() => setModal({
                visible: true,
                title: t("g.areYouSure"),
                message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
                actions: [
                  {
                    key: 0,
                    content: t("g.cancel"),
                    onClick: () => null
                  },
                  {
                    key: 1,
                    content: t("g.delete"),
                    negative: true,
                    onClick: () => removeProfileImage()
                  }
                ]
              }) }
            />
          </div>
        </Grid.Column>
        <Grid.Column>
          <Header as='h1'>
            {profile.first_name} {profile.last_name}
            <Header.Subheader># {profile.id}</Header.Subheader>
          </Header>

          <Label color='red'>
            {profile.type}
          </Label>

          <Divider hidden />

          <Grid divided>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('g.email')}</Grid.Column>
              <Grid.Column>{profile.email}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('g.phone')}</Grid.Column>
              <Grid.Column>{profile.country_code}{profile.phone}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('s.profile.joinDate')}</Grid.Column>
              <Grid.Column>{moment(profile.created_at).fromNow()}</Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} verticalAlign='middle'>
              <Grid.Column>{t('g.status')}</Grid.Column>
              <Grid.Column>
                {(profile.blocked === 'y') 
                ? <Label as='span' color='red'>
                  <Icon name='dont' inverted />
                  {t('s.profile.accountBlocked')}
                </Label>
                : <Label as='span' color='green'>
                  <Icon name='check' inverted />
                  {t('s.profile.accountAllowedAccess')}
                </Label> }
              </Grid.Column>
            </Grid.Row>
          </Grid>

        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Divider hidden />
    <Header as='h2' content={t('g.administration')} />

    <Segment color='red'>
      <Header color='red'>
        {t('s.profile.deleteProfileTitle')}
      </Header>
      
      <div>
        {t('s.profile.deleteProfileDescription')}
      </div>
      
      <Divider hidden />

      <Button
        negative
        icon='trash'
        content={t('g.delete')}
        type='button'
        onClick={() => setModal({
          visible: true,
          title: t("g.areYouSure"),
          message: `${t("g.deletingRecordMessage")} ${t("g.processIsNotReversable")}`,
          actions: [
            {
              key: 0,
              content: t("g.cancel"),
              onClick: () => null
            },
            {
              key: 1,
              content: t("g.delete"),
              negative: true,
              onClick: () => deleteProfile()
            }
          ]
        }) }
      />
    </Segment>

  </InternalPage>
}

export default UserDetails;