import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

// language service
import "./services/i18n";

import 'semantic-ui-css/semantic.min.css'
import 'semantic-ui-less/semantic.less'
import "react-datepicker/dist/react-datepicker.css";

import { 
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import './styling.css';

import Login from './auth/login';
import PasswordReset from './auth/password_reset';
import Logout from './auth/logout';
import Default from './default';

// Users components
import UsersListing from './users/listing';
import UserDetails from './users/details';
import UserForm from './users/form';

// customers
import CustomersListing from './customers/listing';
import CustomerForm from './customers/form';
import CustomerQuickRegisterForm from './customers/quick_register';

// orders components
import OrdersListing from './orders/listing';
import OrderDetails from './orders/details';
import OrderInvoice from './orders/invoice';
import OrdersPurchaseList from './orders/order_purchase_list';
import OrderExport from './orders/export_form';

// carts
import CartsListing from './carts/listing';
import CartsForm from './carts/form';

// businesses components
import ProductsListing from './products/listing';
import ProductForm from './products/form';

// categories components
import CategoriesListing from './categories/listing';
import CategoryForm from './categories/form';

// settings
import Settings from './settings/form';
import SettingsServiceCities from './settings/serviceCities/listing';
import PaymentsListing from './payments/listing';
import OtpListing from './settings/otp/listing';

// check if the user is logged in
var auth:string|null = localStorage.getItem('auth_token');

const root = ReactDOM.createRoot(document.getElementById('root') as Element);

root.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
    <BrowserRouter>
      <Routes>
        {/** General screens **/}
        <Route path="/login" element={<Login />}> </Route>
        <Route path="/password-reset" element={<PasswordReset />} ></Route>
        <Route path="/logout" element={<Logout />}> </Route>
        {/** Customers screens **/}
        <Route path='/customers/quick-register' element={auth ? <CustomerQuickRegisterForm /> : <Login />} ></Route>
        <Route path='/customers/form/:customerId' element={auth ? <CustomerForm /> : <Login />} ></Route>
        <Route path='/customers/form' element={auth ? <CustomerForm /> : <Login />} ></Route>
        <Route path='/customers' element={auth ? <CustomersListing /> : <Login />} ></Route>

        {/** Orders Screens **/}
        <Route path="/orders/invoice/:orderUuid" element={<OrderInvoice />}></Route>
        <Route path="/orders/show/:orderUuid" element={auth ? <OrderDetails /> : <Login />} ></Route>
        <Route path="/orders/purchase-list" element={auth ? <OrdersPurchaseList /> : <Login />} ></Route>
        <Route path="/orders/export" element={auth ? <OrderExport /> : <Login />} ></Route>
        <Route path="/orders" element={auth ? <OrdersListing /> : <Login />} ></Route>

        <Route path="/carts/form/:cartUuid" element={auth ? <CartsForm /> : <Login />} ></Route>
        <Route path="/carts/form" element={auth ? <CartsForm /> : <Login />} ></Route>
        <Route path="/carts" element={auth ? <CartsListing /> : <Login />} ></Route>

        {/** Code below this line needs to revised and pushed above as completed */}
        {/** Users screens **/}
        <Route path="/users/show/:profileId" element={auth ? <UserDetails /> : <Login />} ></Route>
        <Route path="/users/form/:profileId" element={auth ? <UserForm /> : <Login />} ></Route>
        <Route path="/users/form/" element={auth ? <UserForm /> : <Login />} ></Route>
        <Route path="/users" element={auth ? <UsersListing /> : <Login />} ></Route>

        {/** Categories Screens **/}
        <Route path="/products/form/:productId" element={auth ? <ProductForm /> : <Login />} ></Route>
        <Route path="/products/form/" element={auth ? <ProductForm /> : <Login />} ></Route>
        <Route path="/products" element={auth ? <ProductsListing /> : <Login />} ></Route>

        <Route path="/categories/form/:categoryId" element={auth ? <CategoryForm /> : <Login />} ></Route>
        <Route path="/categories/form" element={auth ? <CategoryForm /> : <Login />} ></Route>
        <Route path="/categories" element={auth ? <CategoriesListing />: <Login />} ></Route>

        {/** Settings Screens **/}
        <Route path="/settings/service-cities" element={auth ? <SettingsServiceCities /> : <Login />} ></Route>
        <Route path="/settings" element={auth ? <Settings /> : <Login />} ></Route>

        {/** System **/}
        <Route path="/system/otp/listing" element={auth ? <OtpListing /> : <Login />} ></Route>

        {/** Advertisement Screens **/}
        <Route path="/payments" element={auth ? <PaymentsListing /> : <Login />} ></Route>

        <Route path="/" element={auth ? <Default /> : <Login />} ></Route>
      </Routes>
    </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();