import moment from 'moment';
import { useEffect, useState } from 'react';

// import config
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { 
  Table, 
  Loader, 
  Header, 
  Grid,
  Input,
  Menu,
  Icon,
  Dropdown
} from 'semantic-ui-react';
import { config } from '../config';
import InternalPage from '../framework/internal_page';
import Unauthorized from '../framework/unauthorized';
import ReeLaAPI from '../util/reela_lib';
import { formatDateTime } from '../util/tools';

const CustomersListing = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  // screen states
  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);
  const [loadingCustomers, setLoadingCustomers] = useState<boolean>(false);
  const [customers, setCustomers] = useState<Array<any>|null>(null);

  // identification
  const [searchTerm, setSearchTerm] = useState<string|null>(null);

  // pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  // set the modal
  const [modal, setModal] = useState<any>();

  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'management') management = true;
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);
    profile = null;
  }

  useEffect( () => {
    // set default view
    if(!screenLayout) {
      if(window.innerWidth <= 768) setScreenLayout('cards');
      else setScreenLayout('table');
    }

    getCustomers();
  }, []);

  // run getPost after searchTerm update
  useEffect( () => {
    if(searchTerm && searchTerm.length > 4)
      getCustomers();

    else if(!searchTerm)
      getCustomers();
  }, [searchTerm, resPerPage, currentPage]);

  const getCustomers = async () => {
    if(loadingCustomers) return null;
    setLoadingCustomers(true);
    setCustomers([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.searchTerm = searchTerm;
    reelaApi.page = currentPage;
    reelaApi.resPerPage = resPerPage;

    // if registrar add the registrarId
    if(profile.type !== 'management')
      reelaApi.registrarId = profile.id

    var res:any = await reelaApi.getCustomers();
    setLoadingCustomers(false);

    if(res && res.data) {
      setCustomers(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [
        { content: t('g.ok') }
      ]
    })
  }

  const _renderCustomers = () => {
    if(!customers) return null;
    if(customers.length < 1) return null;

    var render:Array<any> = [];

    customers.map( (customer:any, key:number) => {
      var customerNameLocal:any = customer.name_local || null;

      try {
        if(typeof customerNameLocal === 'string') {
          customerNameLocal = JSON.parse(customerNameLocal);
        }
        
        customerNameLocal = customerNameLocal[localStorage.getItem('language') || 'en']
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') console.warn(
          'Failed to parse the customer name', customerNameLocal, e
        );
        
        customerNameLocal = null;
      }

      render.push(
        <Table.Row 
          key={key} 
          onClick={() => navigate('/customers/form/' + customer.user_id)}
        >
          <Table.Cell>
            {customer.user_id}
          </Table.Cell>
          <Table.Cell>
            {customer.registrar_id}
          </Table.Cell>
          <Table.Cell>
            {customerNameLocal}
          </Table.Cell>
          <Table.Cell>
            {customer.license_number}
          </Table.Cell>
          <Table.Cell>
            {customer.tax_id}
          </Table.Cell>
          <Table.Cell>
            <div>{customer.address_1}</div>
            <div>{customer.address_2}</div>
            <div>{customer.city}, {customer.state}, {customer.country}</div>
          </Table.Cell>
          <Table.Cell>
            {customer.created_at && 
              formatDateTime(customer.created_at)
            }
          </Table.Cell>
          {management && <Table.Cell>
            {formatDateTime(customer.deleted_at)}
          </Table.Cell> }
        </Table.Row>
      )
    });

    return <>{render}</>
  }

  const _renderLoadingRow = () => {
    if(!loadingCustomers) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={9} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  // check if user is authorized
  var allowedUsers = ['management', 'registrar']
  if(!profile || !allowedUsers.includes(profile.type)) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage>
    <Header as='h1'>
      {t('s.customers.title')}
      <Header.Subheader>{t('s.customers.customersListingDescription')}</Header.Subheader>
    </Header>

    <Grid stackable columns={16}>
      <Grid.Row>
        <Grid.Column width={6}>
        </Grid.Column>
        <Grid.Column width={6}>
          <Menu secondary position='right'>
          <Menu.Menu >
            <Menu.Item
              icon='refresh'
              name={t('g.refresh') || 'Refresh'}
              onClick={() => getCustomers()}
            />
          </Menu.Menu>
          <Menu.Menu >
            <Menu.Item
              icon='table'
              name={t('g.tableView') || 'Table View'}
              onClick={() => setScreenLayout('table')}
            />
          </Menu.Menu>
          <Menu.Menu >
            <Menu.Item
              icon='list layout'
              name={t('g.listView') || 'List View'}
              onClick={() => setScreenLayout('cards')}
            />
          </Menu.Menu>
          </Menu>
        </Grid.Column>
        <Grid.Column width={4} textAlign='right'>
          <Input
            fluid
            icon='search'
            placeholder={t('g.search')}
            onChange={(e, { value }) => {
              setLoadingCustomers(false)
              setSearchTerm(value)
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Table primary color='violet' striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{t('g.id')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.registrars.registrar')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.name')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.customers.licenseNumber')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.customers.taxId')}</Table.HeaderCell>
          <Table.HeaderCell>{t('s.serviceCities.serviceCity')}</Table.HeaderCell>
          <Table.HeaderCell>{t('g.createdAt')}</Table.HeaderCell>
          {management && <Table.HeaderCell>
            {t('g.deletedAt')}
          </Table.HeaderCell> }
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {_renderCustomers()}
        {_renderLoadingRow()}
      </Table.Body>
    </Table>

    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>
  </InternalPage>

}

export default CustomersListing;