import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { 
  Modal, 
  Header,
  Segment, 
  Button, 
  Form, 
  Table, 
  Divider, 
  Loader, 
  Label, 
  Menu, 
  Icon, 
  Input, 
  Dropdown,
  Grid
} from "semantic-ui-react";
import InternalPage from "../../framework/internal_page";
import ReeLaAPI from "../../util/reela_lib";

// import config
import { config } from '../../config';
import moment from "moment";

const SystemOTP = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [loadingOTPs, setLoadingOTPs] = useState<boolean>(false);
  const [OTPs, setOTPs] = useState<any[]|null>(null);

  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [slectedType, setSelectedType] = useState<string|null>(null);
  const [orderDirection, setOrderDirection] = useState<string|null>(null);
  const [orderBy, setOrderBy] = useState<string|null>(null);
  const [tableOrderDirection, setTableOrderDirection] = useState<any>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();

  useEffect(() => {
    getOTPs();
  }, []);

  useEffect(() => {
    getOTPs();

    if(orderDirection === 'ASC') setTableOrderDirection('ascending')
    else setTableOrderDirection('descending');
  }, [searchTerm, slectedType, orderDirection, resPerPage, currentPage]);

  const getOTPs = async () => {
    if(loadingOTPs) return null;
    setLoadingOTPs(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    reelaApi.type = slectedType;
    reelaApi.searchTerm = searchTerm;
    reelaApi.currentPage = currentPage;
    reelaApi.resPerPage = resPerPage;
    reelaApi.orderDirection = orderDirection;
    reelaApi.orderByColumn = orderBy;

    var res:any = await reelaApi.getOTPs();
    setLoadingOTPs(false);

    if(res && res.data) {
      var OTPs = res.data;
      var tempOTPs:any[] = [];

      OTPs.forEach((otp:any, key:number) => {
        // handle JSONS
        // var receivedOTPs = res.data;
        var otpPhone = otp.data;

        if(otpPhone && typeof otpPhone === 'string') {
          try {
            otpPhone = JSON.parse(otpPhone);
            otp = {
              ...otp, 
              data: otpPhone
            }
            tempOTPs.push(otp)
          }
          catch(e) {
            if(process.env.NODE_MODE === 'development') 
              console.warn(
                'Failed to parse product name', 
                otpPhone, 
                e
              );
          }
        }
      });

      setOTPs(tempOTPs);
      
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
  }

  const changeSort = (columnName:any) => {
    setOrderBy(columnName);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC': 'ASC');
  }

  const _renderRows = () => {
    if(!OTPs) return null;

    return OTPs.map((otp:any, key:number) => {
      var uuid = otp.uuid;
      uuid = otp.uuid.substring(uuid.length - 5)
      return <Table.Row>
        <Table.Cell width={2}>#{uuid}</Table.Cell>
        <Table.Cell width={2}>{otp.type}</Table.Cell>
        <Table.Cell width={4}>{`${otp.data.country_code} ${otp.data.phone}`}</Table.Cell>
        <Table.Cell width={2}>{otp.code}</Table.Cell>
        <Table.Cell width={4}>{moment(otp.created_at).format(config.momentDateTimeFormat)}</Table.Cell>
      </Table.Row>;
    });
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1'>
      OTPs
      <Header.Subheader>View users OTPs</Header.Subheader>
    </Header>

   

    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Button
            icon='refresh'
            content='Refresh list'
            onClick={() => getOTPs()}
          />
        </Grid.Column>
        <Grid.Column>
          <Input
            fluid
            icon={{ name: 'search', link: true }}
            placeholder={t('g.search')}
            value={searchTerm}
            onChange={(e, { value }) => {
              setSearchTerm(value)
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown 
            placeholder={t('g.type') || 'Type'}
            fluid
            selection
            clearable
            options={[
              {key: 0, value: 'phone', text: t('g.phone')},
              {key: 1, value: 'email', text: t('g.email')},
            ]}
            onChange={(e, {value}) => {
              setSelectedType(value?.toString()||null);
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Table color='violet'  striped selectable stackable sortable>
      <Table.Header>
        <Table.HeaderCell>{t('g.uuid')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.type')}</Table.HeaderCell>
        <Table.HeaderCell>{t('g.phone')}</Table.HeaderCell>
        <Table.HeaderCell>{t('s.auth.otp')}</Table.HeaderCell>
        <Table.HeaderCell
          onClick={() => changeSort('created_at')}
          sorted={orderBy === 'created_at' ? tableOrderDirection : null}
        >{t('g.createdAt')}</Table.HeaderCell>
      </Table.Header>
      <Table.Body>
        {_renderRows()}
      </Table.Body>
    </Table>

    <Divider hidden />
  
    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
      <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>

  </InternalPage>
}

export default SystemOTP;