import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Checkbox, Divider, Grid, Input, Label, Modal, Segment, Table } from "semantic-ui-react";
import { config } from "../config";
import ReeLaAPI from "../util/reela_lib";

import moment from 'moment';


const CategoriesListingRow = (props:any) => {
  const navigate = useNavigate();
  const {t, i18n} = useTranslation();


  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [modal, setModal] = useState<any>(null);

  var categoryName:any = props.category.title_local;

  if(typeof categoryName === 'string') {
    try {
      categoryName = JSON.parse(categoryName);
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') console.warn(e);
      categoryName = '';
    }
  }

  if(typeof categoryName === 'object') {
    categoryName = <div>
      {categoryName.en && <div>{categoryName.en}</div>}
      {categoryName.ar && <div>{categoryName.ar}</div>}
    </div>
  }
  
  var screenLayout = props.screenLayout || 'table';

  if(screenLayout === 'table') {
    return <>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />
      
      <Table.Row warning={props.category.active === 'n' ? true : false}> 
        <Table.Cell onClick={props.onClick}>
          {props.category.id}
        </Table.Cell>
        <Table.Cell onClick={props.onClick}>
          {categoryName}
        </Table.Cell>
        <Table.Cell onClick={props.onClick}>
          {props.category.order}
        </Table.Cell>
        <Table.Cell onClick={props.onClick}>
          {props.category.active === 'y' ? t('g.yes') : t('g.no')}
        </Table.Cell>
        <Table.Cell onClick={props.onClick}>
          {props.category.parent_id === null ? t('g.yes') : t('g.no')}
        </Table.Cell>
      </Table.Row>
    </>
  }

  else {
    return <>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />

      <Divider hidden />
      <Segment>
        <Grid>
          <Grid.Row columns={2} onClick={props.onClick}>
            <Grid.Column>
              <div style={{fontWeight: 'bold'}}>
                #{props.category.id}
              </div>
              <div>{categoryName}</div>
            </Grid.Column>
            <Grid.Column>
              <Grid.Row>
                {t('s.categories.parent')} : {props.category.parent_id === null ? t('g.yes') : t('g.no')}
              </Grid.Row>
              <Grid.Row>
                {t('g.active')} <Label size='mini' color={props.category.active === 'n' ? 'yellow' : 'green'}>
                  {props.category.active === 'n' ? t('g.no') : t('g.yes')}
                </Label>
              </Grid.Row>
                <Grid.Row>
                {t('s.categories.order')} : {props.category.order}
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  }
}

export default CategoriesListingRow;