// print out the form
// print out OTP if not management
// create() or update()

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router";
import { Button, Divider, Form, Header, Segment, Modal, Grid, Dropdown, GridRow, Label } from 'semantic-ui-react';
import { config } from "../config";
import InternalPage from "../framework/internal_page";
import Unauthorized from "../framework/unauthorized";
import ReeLaAPI from "../util/reela_lib";

const CustomerForm = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  let { customerId } = useParams<any>();

  interface customerType {
    user_id: number|string|null,
    registrar_id: any,
    location: {
      lat: string|null,
      lng: string|null
    }|null,
    name_local: {
      en: string|null,
      [key: string]: string|null
    },
    tax_id: string|null,
    license_number: string|null,
    address_1: string|null,
    address_2: string|null,
    service_city_id: any,
    password: string|null
  }

  const [customer, setCustomer] = useState<customerType>({
    user_id: customerId || null,
    registrar_id: null,
    location: null,
    name_local: {
      en: null,
      ar: null
    },
    license_number: null,
    tax_id: null,
    address_1: null,
    address_2: null,
    service_city_id: null,
    password: null
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [isLoadingServiceCities, setIsLoadingServiceCities] = useState<boolean>(false);
  const [serviceCities, setServiceCities] = useState<any>(null);

  const [isLoadingRegistrars, setIsLoadingRegistrars] = useState<boolean>(false);
  const [registrars, setRegistrars] = useState<any>(null);

  // screen states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');
    management = false;

    if(profile.type === 'management') management = true;
  }
  catch(e) {
    console.warn('Error parsing the profile', e);
  }

  useEffect(() => {
    if(!customerId) {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: 'Must specify a customer to edit.',
        actions: [{ content: t('g.ok'), onClick: () => navigate(-1) }]
      });
    }
    
    else {
      getRegistrars();
      getServiceCities();
      getCustomer();
    }
  }, []);

  // > use effect to get the category

  const getCustomer = async () => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.profileId = customer.user_id;

    var res:any = await reelaApi.getProfile();
    setIsProcessing(false);

    if(
      !res || 
      res.status !== 'success' ||
      !res.data ||
      !res.data.type_details
    ) {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok'), onClick: () => navigate(-1) }]
      })

      return null;
    }

    var typeDetails = res.data.type_details;
    var nameLocal = typeDetails.name_local;

    // parse the jsons
    if(nameLocal && typeof nameLocal === 'string') {
      try {
        nameLocal = JSON.parse(nameLocal);
        typeDetails = {...typeDetails, name_local: nameLocal}
      }
      catch(e) {
        if(process.env.NODE_MODE === 'development') console.warn('Failed to parse customer name', nameLocal, e);
      }

    }

    setCustomer({
      ...customer,
      registrar_id: typeDetails.registrar_id,
      location: typeDetails.location,
      name_local: nameLocal,
      tax_id: typeDetails.tax_id,
      license_number: typeDetails.license_number,
      address_1: typeDetails.address_1,
      address_2: typeDetails.address_2,
      service_city_id: typeDetails.service_city_id,
    })
  }

  const getRegistrars = async () => {
    // get the registrars in the system
    if(isLoadingRegistrars) return null;

    setIsLoadingRegistrars(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.type = 'registrar';
    
    var res:any = await reelaApi.getUsers();

    setIsLoadingRegistrars(false);

    if(!res || res.status !== 'success') {
      setModal({
        // error
      });

      return null;
    } 

    var organizedRegistrars:any = [];

    res.data.forEach((registrar:any, key:number) => {
      
      organizedRegistrars.push({
        key: registrar.id,
        text: `(${registrar.id}) ${registrar.first_name} ${registrar.last_name}`,
        value: registrar.id
      })
    });

    setRegistrars(organizedRegistrars);
    return null;
  }

  const getServiceCities = async () => {
    if(isLoadingServiceCities) return null;

    setIsLoadingServiceCities(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    
    var res:any = await reelaApi.getServiceCities();

    setIsLoadingServiceCities(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });

      return null;
    } 

    var organizedServiceCities:any = [];

    res.data.forEach((sc:any, key:number) => {
      var local = sc.local;

      if(local && typeof local === 'string') {
        try { local = JSON.parse(local) }
        catch(e) {
          if(process.env.NODE_MODE === 'development') {
            console.error('Failed to parse service city local', sc, e);
          }

          return null;
        }
      }

      organizedServiceCities.push({
        key: sc.id,
        text: `${local[i18n.language||'en'].city}, ${local[i18n.language||'en'].state}, ${local[i18n.language||'en'].country}`,
        value: sc.id
      })
    });

    setServiceCities(organizedServiceCities);
    return null;
  }

  const processCustomer = async () => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.debug = process.env.REACT_APP_MODE === 'development' ? true : false;
    reelaApi.customerId = customerId;
    reelaApi.serviceCityId = customer.service_city_id;
    reelaApi.taxId = customer.tax_id;
    reelaApi.licenseNumber = customer.license_number;
    reelaApi.address1 = customer.address_1;
    reelaApi.address2 = customer.address_2;
    reelaApi.nameLocal = customer.name_local;
    reelaApi.password = customer.password;
    reelaApi.registrarId = customer.registrar_id;
    
    var res:any = await reelaApi.updateCustomer();

    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.cancel') }]
      });

      return null;
    }

    setModal({
      visible: true,
      title: t('g.processCompleted'),
      message: t('g.processCompletedMessage'),
      actions: [{ content: t('g.ok'), positive: true }]
    })
  }


  const _renderScreenTitle = () => {
    var render:any = t('s.customers.profile');

    if(customerId && customer && customer.name_local) {
      render = customer.name_local['en'];
    }

    return render;
  }

  // check if user is authorized
  var allowedUsers = ['management', 'registrar']
  if(!profile || !allowedUsers.includes(profile.type)) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage loading={isLoading}>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />

    <Header as='h1'>
      {_renderScreenTitle()}
    </Header>

    <Form onSubmit={() => processCustomer()}>
      <Form.Field>
        <Form.Input
          disabled
          label={t('g.id')}
          placeholder={t('g.typeHere')}
          value={customer.user_id || ''}
        />
      </Form.Field>
      <Form.Field>
        <Form.Dropdown
          loading={isLoadingRegistrars}
          disabled={!management}
          label={t('s.registrars.registrarId')}
          placeholder={t('g.selectOne') || 'Select One'}
          fluid
          selection
          options={registrars}
          value={customer.registrar_id}
          onChange={(e, { name, value }) => setCustomer({
            ...customer,
            registrar_id: value||null
          })}
        />
      </Form.Field>
      <Form.Group widths='equal'>
        <Form.Field>
          <Form.Input
            disabled={!management}
            required
            label={`${t('g.name')} ${t('l.english')}`}
            placeholder={t("g.typeHere")}
            value={customer.name_local?.en||''}
            onChange={(e, {value}) => setCustomer({
              ...customer,
              name_local: {
                ...customer.name_local,
                en: value||null
              }
            })}
          />
        </Form.Field>
        <Form.Field>
          <Form.Input
            disabled={!management}
            label={`${t('g.name')} ${t('l.arabic')}`}
            placeholder={t("g.typeHere")}
            value={customer.name_local?.ar||''}
            onChange={(e, {value}) => setCustomer({
              ...customer,
              name_local: {
                ...customer.name_local,
                ar: value||null
              }
            })}
          />
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <Form.Input
          disabled={!management}
          label={t('s.customers.taxId')}
          placeholder={t('g.typeHere')}
          value={customer.tax_id || ''}
          onChange={(e, {value}) => {
            setCustomer({
              ...customer,
              tax_id: value||null
            })
          }}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          disabled={!management}
          label={t('s.customers.licenseNumber')}
          placeholder={t('g.typeHere')}
          value={customer.license_number || ''}
          onChange={(e, {value}) => {
            setCustomer({
              ...customer,
              license_number: value||null
            })
          }}
        />
      </Form.Field>
      {/** Map location with selection **/}

      <Form.Field>
        <Form.Input
          disabled={!management}
          required
          label={t('g.address1')}
          placeholder={t("g.typeHere")}
          value={customer.address_1||''}
          onChange={(e, {value}) => setCustomer({
            ...customer,
            address_1: value
          })}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          disabled={!management}
          label={t('g.address2')}
          placeholder={t("g.typeHere")}
          value={customer.address_2||''}
          onChange={(e, {value}) => setCustomer({
            ...customer,
            address_2: value||null
          })}
        />
      </Form.Field>

      <Form.Field>
        <Form.Dropdown
          disabled={!management}
          required
          label={t('s.serviceCities.serviceCity')}
          placeholder={t('s.serviceCities.selectServiceCity') || 'Select Service City'}
          fluid
          selection
          options={serviceCities}
          value={customer.service_city_id}
          onChange={(e, { name, value }) => setCustomer({
            ...customer,
            service_city_id: value
          })}
        />
      </Form.Field>

      <div>
        <Button
          content={t('g.cancel')}
          type='button'
          onClick={() => navigate(-1)}
        />
        {management && <Button
          primary
          icon='save'
          labelPosition="left"
          content={t('g.save')}
          type='submit'
          disabled={isProcessing}
        />}
      </div>
    </Form>
  </InternalPage>
}

export default CustomerForm;