import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { 
  Button, 
  Header, 
  Table, 
  Loader, 
  Grid, 
  Input, 
  Menu, 
  Dropdown,
  Icon, 
  Modal
} from "semantic-ui-react";

import InternalPage from "../framework/internal_page"
import ReeLaAPI from "../util/reela_lib";
import { URLQuery } from '../util/tools';

// import config
import { config } from '../config';
import Unauthorized from "../framework/unauthorized";
import CategoriesListingRow from "./listing_row";

const CategoryListing = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);
    
  const [loadingCategories, setLoadingCategories] = useState<boolean>(false);

  const [categories, setCategories] = useState<Array<any>|null>(null);
  const [mainCategories, setMainCategories] = useState<Array<any>|null>(null);


  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [isActive, setIsActive] = useState<string|null>(null);
  const [categoryParentId, setCategoryParentId] = useState<number>(0);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);

  }

  useEffect(() => {
    // set default view
    if(!screenLayout) {
      if(window.innerWidth <= 768) setScreenLayout('cards');
      else setScreenLayout('table');
    }
    
    getCategories();
  }, []);

  useEffect( () => {
    getCategories();
  }, [searchTerm, isActive, resPerPage, currentPage, categoryParentId]);

  const getCategories = async () => {
    // get the orders list
    if(loadingCategories) return null
    setLoadingCategories(true);
    setCategories([])

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.searchTerm = searchTerm;
    reelaApi.page = currentPage;
    if (!categoryParentId) reelaApi.listAll = 'y';
    reelaApi.resPerPage = resPerPage;
    reelaApi.active = isActive;
    reelaApi.categoryId = categoryParentId;

    var res:any = await reelaApi.getCategories();

    setLoadingCategories(false);
    
    if(res && res.data) {

      if(!mainCategories) {
        var tempMainCategories:Array<Object> = [];
        var keyValue:number = 0;
  
        for (var i = 0; i < res.data.length; i++) {
  
          if (res.data[i].parent_id === null) {
            var categoryName:any = res.data[i].title_local;
            // transforming the category name from a string to an object
            if(typeof categoryName === 'string') {
              try {
                categoryName = JSON.parse(categoryName);
              }
              catch(e) {
                if(process.env.NODE_MODE === 'development') console.warn(e);
                categoryName = '';
              }
            }
            // this is used later for the filter
            tempMainCategories.push({
              key: keyValue,
              value: res.data[i].id,
              text: (categoryName.en !== null ? categoryName.en : categoryName.ar) || 'err' 
            })
            
            keyValue++;
          } 
        }

        setMainCategories(tempMainCategories)
      }
    
      setCategories(res.data);

      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t("g.ok") }]
    })
  }

  const _renderCategories = () => {
    if(categories === null || !categories) return null;
    if(categories.length < 1) return null;

    var render:Array<any> = [];

    categories.map( (category:any, key:number) => {
      render.push( <CategoriesListingRow
        key={key}
        category={category}
        screenLayout={screenLayout}
        onClick={() => {
          if(management) navigate('/categories/form/' + category.id);
          else return null
        }}
      /> );
    });

    return render;
  }

  const _renderLoadingRow = () => {
    if(!loadingCategories) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  if(!management) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {t("s.categories.title")}
      <Header.Subheader>{t('s.categories.listingDescription')}</Header.Subheader>
    </Header>

    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={t('g.search')}
              value={searchTerm}
              onChange={(e, { value }) => {
                setSearchTerm(value)
              }}
            />
        </Grid.Column>
        <Grid.Column>
          <Dropdown 
            placeholder={t('g.active') || 'Active'}
            fluid
            selection
            clearable
            options={[
              {key: 0, value: 'y', text: t('g.active')},
              {key: 1, value: 'n', text: t('g.inactive')},
            ]}
            onChange={(e, {value}) => {
              setIsActive(value?.toString()||null);
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown 
            placeholder={t('s.orders.orderBy') || 'Order By'}
            fluid
            selection
            clearable
            loading={loadingCategories}
            options={mainCategories || []}
            onChange={(e, {value}) => {
              setCategoryParentId(Number(value));
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  

    <Grid stackable columns={16}>
      <Grid.Column width={6}>
      </Grid.Column>
      <Grid.Column width={6}>
        <Menu secondary position='right'>
        <Menu.Menu >
          <Menu.Item
            icon='refresh'
            name={t('g.refresh') || 'Refresh'}
            onClick={() => getCategories()}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='table'
            name={t('g.tableView') || 'Table View'}
            onClick={() => setScreenLayout('table')}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='list layout'
            name={t('g.listView') || 'List View'}
            onClick={() => setScreenLayout('cards')}
          />
        </Menu.Menu>
        </Menu>
      </Grid.Column>
    </Grid>

    {screenLayout === 'table' && <Table color='violet' striped selectable stackable>
      <Table.Header>
        <Table.HeaderCell width={1}>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('g.name')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.order')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.active')}</Table.HeaderCell>
        <Table.HeaderCell width={5}>{t('s.categories.parent')}</Table.HeaderCell>
      </Table.Header>
        {_renderCategories()}
        {_renderLoadingRow()}
    </Table> }
    
    {screenLayout === 'cards' &&
    <div>
      {_renderCategories()}
    </div> }
    
    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>

  </InternalPage>
}

export default CategoryListing;