/*
 *
 * PROJECT ReeLa
 * Developed by:  3WebBox LLC - 2022
 * 
 * Disclaimor: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

export default {
  buildName: (language, customerName, defReturn='') => {
    var fullName = '';

    if(customerName) {
      try {
        if(typeof customerName === 'string')
        customerName = JSON.parse(customerName);

        // the word 'all' can be used to select all languanges 
        if(language === 'all'){
          for(const [key, value] of Object.entries(customerName)) {
            fullName += `[${String(value)}]`;
          }
        }
        else if(customerName[language]) fullName += customerName[language];
        else if(customerName['en']) fullName += customerName['en'];
        else fullName += '';
      }
      catch(e) {
        if(this.debug) console.warn('processing first name', e);
        fullName += '';
      }
    }

    // title and translation
    if(fullName.length > 0) {
      fullName = fullName;
    }
    else {
      fullName = defReturn;
    }
    
    return fullName;
  },

}