import { useState } from "react";
import { useTranslation } from "react-i18next";
import { 
  Button,
  Input,
  Table,
  Dropdown,
  Modal
} from "semantic-ui-react";
import { config } from "../config";
import ReeLaAPI from "../util/reela_lib";


const ProductPricesRow = (props:any) => {
  const {t, i18n} = useTranslation();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hideRow, setHideRow] = useState<boolean>(false);

  const [tierMaxQuantiy, setTierMaxQuantity] = useState<number>(props.price.tier_max_quantity || 0);
  const [tierPrice, setTierPrice] = useState<number|string>(props.price.tier_price || 0.00);
  const [tierPriceIncTax, setTierPriceIncTax] = useState<number|string>(0.00);

  const [modal, setModal] = useState<any>();

  const updateProductPrice = async () => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.priceId = props.price.id;
    reelaApi.tierMaxQuantity = tierMaxQuantiy;
    reelaApi.tierPrice = tierPrice;

    var res:any = await reelaApi.updateProductPrice();
    
    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t("g.ok") }]
      });

      return null;
    }

    setEditMode(false);
    return null;
  }

  const deleteProductPrice = async () => {
    if(isProcessing) return null;
    setIsProcessing(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.priceId = props.price.id;

    var res:any = await reelaApi.deleteProductPrice();
    
    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t("g.ok") }]
      });

      return null;
    }

    setHideRow(true);
    return null;
  }

  if(hideRow) return null;
  
  return <>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    {!editMode && <Table.Row>
      <Table.Cell>
        {props.price.id}
      </Table.Cell>
      <Table.Cell>
        {props.price.city}, {props.price.state}, {props.price.country}
      </Table.Cell>
      <Table.Cell>
        {tierMaxQuantiy}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {tierPrice} {t(`c.${localStorage.getItem('currency-key')}`)}
      </Table.Cell>
      <Table.Cell textAlign="right">
        {(Number(tierPrice) + (Number(tierPrice) * 0.15)).toFixed(2)} {t(`c.${localStorage.getItem('currency-key')}`)}
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          icon='edit'
          size='tiny'
          onClick={() => {
            setEditMode(!editMode)
          }}
        />
        <Button
          icon='trash'
          negative
          size='tiny'
          onClick={() => {
            setModal({
              visible: true,
              title: t('g.areYouSure'),
              message: t('g.processIsNotReversable'),
              actions: [
                { content: t('g.confirm'), negative: true, onClick: () => deleteProductPrice() },
                { content: t('g.cancel') }
              ]
            });
          }}
        />
      </Table.Cell>
    </Table.Row> }
    
    {editMode && <Table.Row>
      <Table.Cell>
        {props.price.id}
      </Table.Cell>
      <Table.Cell>
        {props.price.city}, {props.price.state}, {props.price.country}
      </Table.Cell>
      <Table.Cell>
        <Input
          required
          placeholder='0' 
          value={tierMaxQuantiy.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setTierMaxQuantity(Number(value))
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Input
          required
          placeholder='0.00'
          label={t(`c.${localStorage.getItem('currency-key')}`)}
          labelPosition="right"
          value={tierPrice.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setTierPrice(value);
            setTierPriceIncTax((Number(value) + (Number(value) * 0.15)));
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Input
          required
          placeholder='0.00'
          label={t(`c.${localStorage.getItem('currency-key')}`)}
          labelPosition="right"
          value={tierPriceIncTax.toString()||''}
          onChange={(e, {value}) => {
            // add validation to accept numbers and . only
            setTierPrice((Number(value) / (1.15)).toFixed(2));
            setTierPriceIncTax(value);
          }}
        />
      </Table.Cell>
      <Table.Cell textAlign="right">
        <Button
          icon='close'
          size='tiny'
          onClick={() => {
            setTierMaxQuantity(props.price.tier_max_quantity);
            setTierPrice(props.price.tier_price);
            setEditMode(!editMode)
          }}
        />
        <Button
          icon='save'
          primary
          size='tiny'
          content={t('g.update')}
          onClick={() => updateProductPrice()}
        />
      </Table.Cell>
    </Table.Row> }
  </>
}

export default ProductPricesRow;