import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import { 
  Button, 
  Header, 
  Table, 
  Loader, 
  Grid, 
  Input, 
  Menu, 
  Dropdown,
  Icon, 
  Modal
} from "semantic-ui-react";

import InternalPage from "../framework/internal_page"
import ReeLaAPI from "../util/reela_lib";
import { URLQuery } from '../util/tools';

// import config
import { config } from '../config';
import Unauthorized from "../framework/unauthorized";
import ProductsListingRow from "./listing_row";

const ProductsListing = (props:any) => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();

  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);
    
  const [loadingProducts, setLoadingProducts] = useState<boolean>(false);
  const [products, setProducts] = useState<Array<any>|null>(null);

  const [serviceCityId, setServiceCityId] = useState<string|number|null>(null);
  const [vendorId, setVendorId] = useState<string|number|null>(URLQuery('vendorId', window)||null);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [isAvailable, setIsAvailable] = useState<string|null>(null);
  const [orderDirection, setOrderDirection] = useState<string|null>(null);
  const [orderBy, setOrderBy] = useState<string|null>(null);

  const [tableOrderDirection, setTableOrderDirection] = useState<any>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;
  let vendor:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;
    if(profile.type === 'vendor') vendor = true;
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);

  }

  useEffect(() => {
    // set default view
    if(!screenLayout) {
      if(window.innerWidth <= 768) setScreenLayout('cards');
      else setScreenLayout('table');
    }

    if(vendor) setVendorId(profile.type_details.id);
    
    getProducts();
    setOrderBy(null);
    setOrderDirection('ASC');
  }, []);

  useEffect( () => {
    getProducts();

    if(orderDirection === 'ASC') setTableOrderDirection('ascending')
    else setTableOrderDirection('descending');
  }, [vendorId, searchTerm, isAvailable, orderDirection, resPerPage, currentPage]);

  const getProducts = async () => {
    // get the orders list
    if(loadingProducts) return null
    setLoadingProducts(true);
    setProducts([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.searchTerm = searchTerm;
    reelaApi.serviceCityId = serviceCityId;
    reelaApi.vendorId = vendorId;
    reelaApi.page = currentPage;
    reelaApi.resPerPage = resPerPage;
    reelaApi.isAvailable = isAvailable;
    reelaApi.orderDirection = orderDirection;
    reelaApi.orderByColumn = orderBy;

    var res:any = await reelaApi.getProducts();

    setLoadingProducts(false);
    
    if(res && res.data) {
      setProducts(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t("g.ok") }]
    })
  }

  const changeSort = (columnName:any) => {
    setOrderBy(columnName);
    setOrderDirection(orderDirection === 'ASC' ? 'DESC': 'ASC');
  }

  const _renderProducts = () => {
    if(products === null || !products) return null;
    if(products.length < 1) return null;

    var render:Array<any> = [];

    products.map( (product:any, key:number) => {
      render.push( <ProductsListingRow
        key={key}
        product={product}
        screenLayout={screenLayout}
        vendorId={vendorId}
        onClick={() => {
          if(management) navigate('/products/form/' + product.id);
          else return null
        }}
      /> );
    });

    return render;
  }

  const _renderLoadingRow = () => {
    if(!loadingProducts) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  if(!management && !vendor) {
    return <InternalPage>
      <Unauthorized />
    </InternalPage>
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {t("s.products.title")}
      <Header.Subheader>{t('s.products.listingDescription')}</Header.Subheader>
    </Header>

    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
            <Input
              fluid
              icon={{ name: 'search', link: true }}
              placeholder={t('g.search')}
              value={searchTerm}
              onChange={(e, { value }) => {
                setSearchTerm(value)
              }}
            />
        </Grid.Column>
        {!vendorId ? <>
          <Grid.Column>
            <Dropdown 
              placeholder={t('g.availability') || 'Availability'}
              fluid
              selection
              clearable
              options={[
                {key: 0, value: 'y', text: t('g.available')},
                {key: 1, value: 'n', text: t('g.unavailable')},
              ]}
              onChange={(e, {value}) => {
                setIsAvailable(value?.toString()||null);
              }}
            />
          </Grid.Column>
        </>
        : null }
      </Grid.Row>
    </Grid>
  

    <Grid stackable columns={16}>
      <Grid.Column width={6}>
      {vendorId ? 
        <Button
          primary
          labelPosition='left'
          icon='share square'
          content={'Export'}
          onClick={() => alert('Export the products database in importable format')} 
        />
      : null}
      </Grid.Column>
      <Grid.Column width={6}>
        <Menu secondary position='right'>
        <Menu.Menu >
          <Menu.Item
            icon='refresh'
            name={t('g.refresh') || 'Refresh'}
            onClick={() => getProducts()}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='table'
            name={t('g.tableView') || 'Table View'}
            onClick={() => setScreenLayout('table')}
          />
        </Menu.Menu>
        <Menu.Menu >
          <Menu.Item
            icon='list layout'
            name={t('g.listView') || 'List View'}
            onClick={() => setScreenLayout('cards')}
          />
        </Menu.Menu>
        </Menu>
      </Grid.Column>
    </Grid>

    {screenLayout === 'table' && 
    <Table color='violet' striped selectable stackable sortable>
      <Table.Header>
        <Table.HeaderCell width={1}>{t('g.id')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>SKU</Table.HeaderCell>
        <Table.HeaderCell width={2}>Barcode</Table.HeaderCell>
        <Table.HeaderCell width={5}>{t('g.name')}</Table.HeaderCell>
        {!vendorId && <>
          <Table.HeaderCell 
            width={2}
            onClick={() => changeSort('listing_order')}
            sorted={orderBy === 'listing_order' ? tableOrderDirection : null}
          >{t('s.products.listingOrder')}</Table.HeaderCell>
          <Table.HeaderCell 
            width={3}
            onClick={() => changeSort('created_at')}
            sorted={orderBy === 'created_at' ? tableOrderDirection : null}
          >{t('g.createdAt')}</Table.HeaderCell>
        </>}
        {vendorId && <>
          <Table.HeaderCell width={3}>{t('s.orders.price')}</Table.HeaderCell>
          <Table.HeaderCell width={3}>{t('s.orders.pit_full')}</Table.HeaderCell>
          <Table.HeaderCell width={2}>{t('s.products.availability')}</Table.HeaderCell>
        </> }
      </Table.Header>
      {_renderProducts()}
      {_renderLoadingRow()}
    </Table> }
    
    {screenLayout === 'cards' &&
    <div>
      {_renderProducts()}
    </div> }
    
    <Menu secondary>
      <Menu.Item
        name='previous'
        onClick={() => {
          if(currentPage === 1) return null;
          else setCurrentPage(currentPage - 1)
        }}
      >
        <Icon name='angle left' />
      </Menu.Item>
      <Menu.Item
        name='page number'
      >
        <Input
          value={currentPage}
          onChange={(e, { value }) => setCurrentPage(parseInt(value))}
        />
      </Menu.Item>
      <Menu.Item
        name='next'
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <Icon name='angle right' />
      </Menu.Item>
      <Menu.Menu position='right'>
        <span>
          <span style={{paddingRight: '10px'}}>{t('g.resPerPage')}:</span>
          <Dropdown
            inline
            onChange={(e, data) => setResPerPage(Number(data.value))}
            placeholder={resPerPage.toString()}
            options={[
              { key: 15, text: 15, value: 15 },
              { key: 30, text: 30, value: 30 },
              { key: 60, text: 60, value: 60 },
              { key: 200, text: 200, value: 200 },
            ]}
          />
        </span>
      </Menu.Menu>
    </Menu>

  </InternalPage>
}

export default ProductsListing;