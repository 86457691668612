/**
 *
 * PROJECT ReeLa
 * Developed by:  3WebBox LLC - 2023
 * 
 * Disclaimer: Please make sure to read related documentation before
 * making any changes to the code. Modify the code under your own
 * responsibility. for help please contact 3WebBox.
 * 
 * https://3webbox.com  : support@3webbox.com
 * 
 * 
 */

const packageManager = require('../package.json');

export const config = {
  // This object provides all the global configurations relatred
  // to the application only and not the account using the application.

  // Version
  version:                               packageManager.version,

  momentDateTimeFormat:                  'hh:mm A DD/MM/YYYY',
  momentDateFormat:                      'DD/MM/YYYY',

  // Google map API key
  googleMapAPIKey:                        "AIzaSyCAJtVqyaNqcU0w9JM2NjBGye0ytvPjr1k",
}
