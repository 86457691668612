import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// import config
import { config } from '../config';

// import component
import { Label, Menu } from 'semantic-ui-react';

// translation
import { useTranslation } from "react-i18next";

const SideMenu = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [profile, setUserProfile] = useState<{[key: string]: any}|null>(null);

  useEffect( () => {
    loadProfile();
  }, []);

  var logout = async() => {
    // redirect to login screen
    navigate('/logout');
  }

  // load the stored profile from the login
  var loadProfile = async () => {
    // get user profile
    try {
      var storedProfile:string|null = localStorage.getItem('profile');

      if(storedProfile) {
        var tempProfile:any = JSON.parse( storedProfile );
        setUserProfile(tempProfile);
      }
      
      else logout();
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') console.error(e);
      // profile not set
      // logout
      logout();
    }
  }

  // Menu
  // permission:Array 'all', 'admin', 'business', 'user', 'moderator'
  let mainMenu = [
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.default.title'),
      onClick: () => navigate('/' ,{replace: true}),
    },
    {
      permission: ['admin', 'moderator', 'business'],
      title:  t('s.profile.title'),
      onClick: () => {
        if(profile) navigate('/users/show/' + profile.uuid ,{replace: true})
        else return null
      },
    },
    {
      permission: ['all'],
      title:  t('s.auth.logout'),
      onClick: logout
    }
  ];

  let customersMenu = [
    {
      permission: ['management', 'registrar'],
      title:  t('s.customers.title'),
      onClick: () => navigate('/customers',{replace: true}),
    },
    {
      permission: ['management', 'registrar'],
      title:  t('s.customers.quickRegister'),
      onClick: () => navigate('/customers/quick-register', {replace: true}),
    }
  ];

  let ordersMenu = [
    {
      permission: ['management', 'vendor', 'driver'],
      title: t('s.orders.title'),
      onClick: () => navigate('/orders',{replace: true}),
    },
    {
      permission: ['management'],
      title: `${t('s.orders.purchaseList')} `,
      onClick: () => navigate('/orders/purchase-list',{replace: true}),
    }
  ];

  let cartsMenu = [
    {
      permission: ['management', 'registrar'],
      title: t('s.carts.title'),
      onClick: () => navigate('/carts',{replace: true}),
    },
    {
      permission: ['management', 'registrar'],
      title: t('g.addNew'),
      onClick: () => navigate('/carts/form',{replace: true}),
    }
  ];

  let productsMenu = [
    {
      permission: ['management', 'vendor'],
      title: t('s.products.title'),
      onClick: () => navigate('/products',{replace: true}),
    },
    {
      permission: ['management'],
      title: t('g.addNew'),
      onClick: () => navigate('/products/form',{replace: true}),
    }
  ];

  let categoriesMenu = [
    {
      permission: ['management'],
      title: t('s.categories.title'),
      onClick: () => navigate('/categories',{replace: true}),
    },
    {
      permission: ['management'],
      title: t('g.addNew'),
      onClick: () => navigate('/categories/form',{replace: true}),  
    }
  ]

  let systemMenu = [
    {
      permission: ['management'],
      title: t('s.users.title'),
      onClick: () => navigate('/users',{replace: true}),
    },
    {
      permission: ['management'],
      title:  t('s.payments.title'),
      onClick: () => navigate('/payments',{replace: true}),
    },
    {
      permission: ['management'],
      title:  t('s.settings.title'),
      onClick: () => navigate('/settings',{replace: true}),
    }
  ];

  const _renderAccountTypeLabel = () => {
    var labelColor:'grey'|'violet'|'orange'|'brown'|'green' = 'grey';
    var labelText = null;

    if(!profile || !profile.type) {
      labelText = 'Error';
    } 
    else {
      switch(profile.type) {
        case 'management':
          labelColor = 'orange';
        break;

        case 'registrar':
          labelColor = 'green';
        break;

        case 'vendor':
          labelColor = 'violet';
        break;

        case 'driver':
          labelColor = 'brown';
        break;
      }

      labelText = profile.type;
    }

    return <div>
      <Label color={labelColor}>
        <span style={{textTransform: 'capitalize'}}>
          {t(`g.${labelText}`)}
        </span>
      </Label>
    </div>
  }

  return (
    <>
    {_renderAccountTypeLabel()}
    <Menu vertical fluid secondary>
      <Menu.Item>
        <Menu.Header content={t('g.general')} />
        <Menu.Menu>
          {mainMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      
      {(
        profile?.type === 'registrar' || 
        profile?.type === 'management'
      ) ?
      <Menu.Item>
        <Menu.Header content={t('s.customers.title')} />
        <Menu.Menu>
          {customersMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
      
      {(
        profile?.type === 'driver' || 
        profile?.type === 'customer' ||
        profile?.type === 'vendor' ||
        profile?.type === 'management'
      ) ?
      <Menu.Item>
        <Menu.Header content={t('s.orders.title')} />
        <Menu.Menu>
          {ordersMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }

      {(
        profile?.type === 'management' ||
        profile?.type === 'registrar'
      ) ?
      <Menu.Item>
        <Menu.Header content={t('s.carts.title')} />
        <Menu.Menu>
          {cartsMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
      
      {(
        profile?.type === 'vendor' ||
        profile?.type === 'management'
      ) ?
      <Menu.Item>
        <Menu.Header content={t('s.products.title')} />
        <Menu.Menu>
          {productsMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }

      {( profile?.type === 'management' ) ?
      <Menu.Item>
        <Menu.Header content={t('s.categories.title')} />
        <Menu.Menu>
          {categoriesMenu.map( (item, key) => {
             // if user profile is not set - don't print
            if (!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission. includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null}
      
      {(profile?.type === 'management') ?
      <Menu.Item>
        <Menu.Header content={t('g.administration')} />
        <Menu.Menu>
          {systemMenu.map( (item, key) => {
            // if user profile is not set - don't print
            if(!profile) return null;

            // if user type is not in permission array
            // and permission doesn't include 'all'
            if(
              !item.permission.includes( profile.type ) &&
              !item.permission.includes('all')
            ) return null;

            return <Menu.Item
              key={key}
              name={item.title}
              onClick={item.onClick}
            />
          })}
        </Menu.Menu>
      </Menu.Item>
      : null }
    </Menu>

    <div style={{padding: 10, fontSize: 9, color: '#999'}}>
      {config.version} <br />
      {t('g.rights')}
    </div>

    </>
  )
}

export default SideMenu;