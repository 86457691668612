import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Checkbox, Divider, Dropdown, Grid, Input, Modal, Segment, Table, Button} from "semantic-ui-react";
import { config } from "../config";
import ReeLaAPI from "../util/reela_lib";
import { displayPrice, formatDate } from "../util/tools";


const OrderProductRow = (props:any) => {
  const {t, i18n} = useTranslation();
  
  const [product, setProduct] = useState<any>(props.product||null);

  const [ItemQuantity, setItemQuantity] = useState<any>(product.quantity|0); 

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [modal, setModal] = useState<any>(null);

  const updateFulfillment = async (fulfillment:any) => {
    if(isProcessing) return false;
    setIsProcessing(true);

    // basedo on current availability status
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderProductId = product.id;
    reelaApi.fulfillment = fulfillment;

    var res:any = await reelaApi.updateOrderProduct();

    setIsProcessing(false);

    if(!res || res.status !== 'success') {
      // set modal fail
      setModal({
        visible: true,
        title: t("g.failedToProcessRequest"),
        message: reelaApi.getErrorsString(),
        actions: [{ content: t('g.ok') }]
      });
    }

    else {
      setProduct({
        ...product,
        fulfillment: fulfillment
      })
    }
  }

  const updateOrderItem = async (action:'delete'|'update', orderItemId:any, quantity:number|null) => {
    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');
    reelaApi.orderProductId = orderItemId;
    reelaApi.orderUuid = props.orderUuid;

    var res:any = null;

    if(action === 'delete' || Number(quantity) === 0) {
      res = await reelaApi.deleteOrderProduct();
    }

    if(action === 'update') {
      if(Number(quantity) > 0) {
        reelaApi.quantity = quantity;
        reelaApi.productId = props.product.product_id;
        
        res = await reelaApi.updateOrderProduct();
      }
      else {
        props.setModal({
          visible: true,
          title: t('g.failedToProcessRequest'),
          message: t('g.missingRequirements'),
          actions: [
            {
              key: 0,
              content: t("g.ok"),
              onClick: () => null
            }
          ]
        });
      }
    }

    if(!res) {
      if(process.env.NODE_MODE === 'development') console.error('Error with the response');
    }
    else if (res.status === "fail") {
      props.setModal({
        visible: true,
        title: t('g.failedToProcessRequest'),
        message: t('g.failedToProcessRequestMessage'),
        actions: [
          {
            key: 0,
            content: t("g.tryAgain"),
            onClick: () => updateOrderItem(action, orderItemId, quantity)
          }
        ]
      });
    }
    else if (res.status === "success") {
      props.setModal({
        visible: true,
        title: t('g.processCompleted'),
        message: t('g.processCompletedMessage'),
        actions: [
          {
            key: 0,
            content: t("g.ok"),
            color: "green",
            onClick: () => null
          }
        ]
      });
    }
    
    if(await props.getOrder() === true){

      return null;
    }
  }

  // product name
  var itemName:any = product.name_local;
  var vendorDetails:any = product.vendor_details;
  var vendorName:any = null;
  var vendorColor:string = '#fc9803';
  var fulfillmentChangeDisabled:boolean = false;
  
  if(itemName && typeof itemName === 'string') {
    try {
      itemName = JSON.parse(itemName)[i18n.language||'en'];
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') 
        console.error('Product name or description did not failed to parse', e);
    }
  }
  
  if(vendorDetails && typeof vendorDetails === 'string') {
    try {
      vendorDetails = JSON.parse(vendorDetails);
      vendorName = vendorDetails.name_local[i18n.language||'en'];
    }
    catch(e) {
      if(process.env.NODE_MODE === 'development') 
        console.error('Product name or description did not failed to parse', e);
    }
  }

  if (props.orderStatus === 'delivered' || props.orderStatus === 'cancelled' ) {
    fulfillmentChangeDisabled = true;
  }

  var screenLayout = props.screenLayout || 'table';
  
  if(screenLayout === 'table') {
    return <>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />

      <Table.Row disabled={isProcessing}>
        <Table.Cell verticalAlign="top">
          {product.id}
        </Table.Cell>
        <Table.Cell verticalAlign="top" width={3}>
          <div style={{fontWeight: 'bold'}}>
            {itemName}
          </div>
          <div>
            {product.sku}
          </div>
          <div>
            {product.barcode}
          </div>
          {(props.management || props.vendor) &&
          <div style={{color: vendorColor}}>
            {vendorName}
          </div>}
        </Table.Cell>
        <Table.Cell verticalAlign="top" textAlign='right'>
          {product.tax_value}
        </Table.Cell>
        <Table.Cell verticalAlign="top" textAlign='right'>
          <div>{displayPrice(product.price)}</div>
          {(props.management || props.vendor) && <div style={{color: vendorColor}}>
            {displayPrice(product.vendor_price)}
          </div> }
        </Table.Cell>
        <Table.Cell verticalAlign="top" textAlign='right'>
          {displayPrice(product.tax_per_unit)}
        </Table.Cell>
        <Table.Cell verticalAlign="top" textAlign='right'>
          {displayPrice(product.price_inc_tax)}
        </Table.Cell>
        {!props.editMode ? 
          <Table.Cell verticalAlign="top" textAlign='right'>
            {product.quantity}
          </Table.Cell>
          :
          <Table.Cell>
            <Input 
              fluid
              value={ItemQuantity}
              onChange={(e,data) => {
                setItemQuantity(Number(data.value));
              }}
            />
          </Table.Cell>
        }
        
        <Table.Cell verticalAlign="top" textAlign='right'>
          {displayPrice(product.tax_total)}
        </Table.Cell>
        <Table.Cell verticalAlign="top" textAlign='right'>
          {displayPrice(product.total)}
        </Table.Cell>
        <Table.Cell verticalAlign="top" textAlign='right'>
          {displayPrice(product.total_inc_tax)}
        </Table.Cell>
        {(props.management || props.vendor) && <>
          <Table.Cell verticalAlign="top" textAlign='right'>
            {displayPrice(product.system_commission)}
          </Table.Cell>
          <Table.Cell verticalAlign="top" textAlign='right'>
            <div>{displayPrice(product.system_share)}</div>
            <div style={{color: vendorColor}}>
              {displayPrice(product.vendor_share)}
            </div>
          </Table.Cell>
          {!props.editMode ? 
            <Table.Cell verticalAlign="top" textAlign='right'>
              <Dropdown
                disabled={fulfillmentChangeDisabled}
                selection
                fluid
                options={[
                  {key: 0, text: '-', value: ''},
                  {key: 1, text: 'Not available', value: 'not available'},
                  {key: 2, text: 'Limited', value: 'limited'},
                  {key: 3, text: 'Ready', value: 'ready'}
                ]}
                value={product.fulfillment||''}
                onChange={(e, { name, value }) => updateFulfillment(value)}
              />
            </Table.Cell>
            :
            <Table.Cell verticalAlign="middle" textAlign='center'>
              <Button 
                primary 
                icon='save'
                onClick={(e,data) => updateOrderItem("update", product.id, ItemQuantity)}
              />
              <Button 
                negative 
                icon='trash'
                onClick={(e,data) => {
                  props.setModal({
                    visible: true,
                    title: t('g.areYouSure'),
                    message: t('s.orders.deleteOrderProductDescription'),
                    actions: [
                      {
                        key:0,
                        content: t('g.cancel'),
                        onClick: () => null
                      },
                      {
                        key:1,
                        content: t('g.delete'),
                        color: "red",
                        onClick: () => updateOrderItem("delete", product.id, null)
                      }
                    ]
                  })}
                }
              />
            </Table.Cell>
          }
          
        </>}
      </Table.Row>
    </>
  }
  else {
    return <>
      <Modal
        size='mini'
        onClose={() => setModal(null)}
        open={(modal && modal.visible) ? true : false}
        header={modal ? modal.title : null}
        content={modal ? modal.message : null}
        actions={modal ? modal.actions : ['Close']}
      />

      <Divider hidden />
      <Segment padded>
        <Grid >
          <Grid.Row columns={2} >
            <Grid.Column>
              <div> #{product.id}</div>
              <div style={{fontWeight: 'bold'}}>
                
                {itemName}
              </div>
              <div>
                SKU: {product.sku}
              </div>
              <div>
                barcode: {product.barcode}
              </div>
            </Grid.Column>
            <Grid.Column>
            {(props.management || props.vendor) &&
              <div style={{color: vendorColor}}>
                {vendorName}
              </div>}
            </Grid.Column>
          </Grid.Row>
          <Segment>
            <Grid celled>
              <Grid.Row columns={2}>
                <Grid.Column>
                  {t('s.orders.tax')}
                </Grid.Column>
                <Grid.Column>
                  {product.tax_value}
                </Grid.Column>
              </Grid.Row>
              <Grid.Row  columns={2}>
                <Grid.Column verticalAlign='middle'>
                  <div>{t('s.orders.ppu')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.price)}</div>
                  {(props.management || props.vendor) && <div style={{color: vendorColor}}>
                    {displayPrice(product.vendor_price)}
                  </div> }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div>{t('s.orders.tpu')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.tax_per_unit)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div>{t('s.orders.pit')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.price_inc_tax)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div>{t('s.orders.qty')}</div>
                </Grid.Column>
                <Grid.Column>
                  {props.editMode ? 
                  <Input 
                  fluid
                  value={ItemQuantity}
                  onChange={(e,data) => {
                    setItemQuantity(Number(data.value));
                  }}
                />
                :
                  <div>{product.quantity}</div>
                }
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div>{t('s.orders.tt')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.tax_total)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div>{t('s.orders.t')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.total)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div>{t('s.orders.tit')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.total_inc_tax)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <div>{t('s.orders.sc')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.system_commission)}</div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign='middle'>
                  <div>{t('s.orders.vs')}</div>
                </Grid.Column>
                <Grid.Column>
                  <div>{displayPrice(product.system_share)}</div>
                  <div style={{color: vendorColor}}>
                    {displayPrice(product.vendor_share)}
                  </div>
                </Grid.Column>
              </Grid.Row>
              {!props.editMode && 
              <Grid.Row columns={2}>
                <Grid.Column verticalAlign='middle'>
                  <div>{t('s.orders.fulfilment')}</div>
                </Grid.Column>
                <Grid.Column>
                  <Dropdown
                    disabled={fulfillmentChangeDisabled}
                    selection
                    fluid
                    options={[
                      {key: 0, text: '-', value: ''},
                      {key: 1, text: 'Not available', value: 'not available'},
                      {key: 2, text: 'Limited', value: 'limited'},
                      {key: 3, text: 'Ready', value: 'ready'}
                    ]}
                    value={product.fulfillment||''}
                    onChange={(e, { name, value }) => updateFulfillment(value)}
                  />
                </Grid.Column>
              </Grid.Row>}
            </Grid>
          </Segment>
          {props.editMode && <>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Button 
                  primary 
                  icon='save'
                  onClick={(e,data) => updateOrderItem("update", product.id, ItemQuantity)}
                />
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <Button 
                  negative 
                  icon='trash'
                  onClick={(e,data) => {
                    props.setModal({
                      visible: true,
                      title: t('g.areYouSure'),
                      message: t('s.orders.deleteOrderProductDescription'),
                      actions: [
                        {
                          key:0,
                          content: t('g.cancel'),
                          onClick: () => null
                        },
                        {
                          key:1,
                          content: t('g.delete'),
                          color: "red",
                          onClick: () => updateOrderItem("delete", product.id, null)
                        }
                      ]
                    })}
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </>}
        </Grid>
      </Segment>
    </>
  }
}

export default OrderProductRow;