
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { 
  Button, 
  Menu, 
  Grid, 
  Table, 
  Input, 
  Header, 
  Loader, 
  Icon, 
  Dropdown,
  Modal, 
  Form, 
  Container,
  Segment,
  Divider,
  TableHeader,
  Label,
  Checkbox
} from "semantic-ui-react";
import InternalPage from "../framework/internal_page";
import ReeLaAPI from '../util/reela_lib';

// import config
import { config } from '../config';

import RLServiceCities from '../util/funcs/service_cities';

const OrdersPurchaseList = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [loadingOrders, setLoadingOrders] = useState<boolean>(false);
  const [screenLayout, setScreenLayout] = useState<'table'|'cards'|null>(null);
  const [searchTerm, setSearchTerm] = useState<string|null>(null);
  const [purchaseList, setPurchaseList] = useState<Array<any>|null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<string|null>(null);
  const [orderDirection, setOrderDirection] = useState<string|null>(null);
  const [orderStatus, setOrderStatus] = useState<string|null>(null);
  const [resPerPage, setResPerPage] = useState<number>(15);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [loadingCities, setLoadingCities] = useState<boolean>(false);
  const [selectedServiceCity, setSelectedServicecCity] = useState<any|null>(null);
  const [serviceCities, setServiceCities] = useState<any[]|null>(null);

  const [modal, setModal] = useState<any>();

  // set lets
  let profile:any = null;
  let management:boolean = false;

  try {
    profile = JSON.parse(localStorage.getItem('profile') || '');

    if(profile.type === 'management') management = true;
  }
  catch(e) {
    if(process.env.NODE_MODE === 'development') console.warn('Error parsing the profile', e);
  }

  useEffect(() => {
    // set default view
    if(!screenLayout) {
      if(window.innerWidth <= 768) setScreenLayout('cards');
      else setScreenLayout('table');
    }
    
    getOrdersPurchaseList();
    setOrderBy(null);
    setOrderDirection('ASC');
  }, []);

  // run getPost after searchTerm update
  useEffect( () => {
    getOrdersPurchaseList();
    getServiceCities(); 
  }, [searchTerm, resPerPage, currentPage, orderBy, orderDirection, orderStatus, selectedServiceCity]);

  const getOrdersPurchaseList = async () => {
    // get the purchaseList list
    if(loadingOrders) return null
    setLoadingOrders(true);
    setPurchaseList([]);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    reelaApi.searchTerm = searchTerm;
    reelaApi.serviceCityId = selectedServiceCity;
    reelaApi.orderStatus = orderStatus;
    reelaApi.orderDirection = orderDirection;
    reelaApi.orderBy = orderBy;
    reelaApi.showAll = 'y';

    var res:any = await reelaApi.getPurchaseList();

    console.log('res', res);
    setLoadingOrders(false);

    if(res && res.data) {
      setPurchaseList(res.data);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: reelaApi.getErrorsString(),
      actions: [{ content: t('g.ok') }]
    })
  }

  const getServiceCities = async () => {
    if(loadingCities) return null;
    setLoadingCities(true);

    var reelaApi:any = new ReeLaAPI;
    reelaApi.baseAPI = localStorage.getItem('domain_target');
    reelaApi.authToken = localStorage.getItem('auth_token');

    var res:any = await reelaApi.getServiceCities();
    setLoadingCities(false);

    if(res && res.data) {
      var serviceCities = res.data;
      var tempServiceCities:any[any] = [];

      serviceCities.map((item:any) => {
        tempServiceCities.push({
          key: item.id,
          value: item.id,
          text: RLServiceCities.buildLabel(i18n.language, item)
        })
      })

      setServiceCities(tempServiceCities);
      return null;
    }

    setModal({
      visible: true,
      title: t("g.failedToProcessRequest"),
      message: t("g.failedToProcessRequestMessage"),
      actions: [{ key: 0, content: t("g.ok") }]
    });
  }

  const _renderPurchaseList = () => {
    // render the table body
    if(
      !purchaseList
      || purchaseList.length < 1
    ) return null;

    var render:Array<any> = [];
    var itemSumQuantity = 0;
    var sumQuantities = 0;
    var vendorId = 0;
    var productId = 0;
    var productName:any = null;
    var vendorName:any = null;

    var itemPrice = 0;
    var itemSumTotal = 0;
    var itemSumTotalIncTax = 0;
    var itemSumVendorTotal = 0;
    var itemSumVendorTotalIncTax = 0;

    var allTotals = 0;
    var allTotalsIncTax = 0;
    var allVendorsTotals = 0;
    var allVendorsTotalsIncTax = 0;

    purchaseList.map((product:any, key) => {
      if(
        vendorId !== product.vendor_id
        || productId !== product.product_id
      ) {
        vendorId = product.vendor_id;
        productId = product.product_id;
        productName = product.name_local;
        vendorName = product.vendor_name_local;

        itemPrice = product.price;
        itemSumTotal = 0;
        itemSumTotalIncTax = 0;
        itemSumVendorTotal = 0;
        itemSumVendorTotalIncTax = 0;

        if(typeof productName === 'string') {
          try {
            productName = JSON.parse(productName);
          }
          catch(e) {
            if(process.env.NODE_MODE === 'development') {
              console.warn(
                'error with parsing produc name', 
                productName, e
              );
            }
  
            productName = '/** Err';
          }
        }

        if(typeof vendorName === 'string') {
          try {
            vendorName = JSON.parse(vendorName);
          }
          catch(e) {
            if(process.env.NODE_MODE === 'development') {
              console.warn(
                'error with parsing vendor name', 
                vendorName, e
              );
            }
  
            vendorName = '/** Err';
          }
        }
      }

      var productServiceCity = serviceCities?.find(
        (serviceCityId:any) => serviceCityId.value === product.vendor_service_city_id
      );

      itemSumQuantity += product.quantity;
      sumQuantities += product.quantity;
    
      itemSumTotal += product.total;
      itemSumTotalIncTax += product.total_inc_tax;

      var itemVendorTotal = product.vendor_price * product.quantity
      itemSumVendorTotal += (itemVendorTotal);
      itemSumVendorTotalIncTax += (itemVendorTotal + (itemVendorTotal * product.vendor_tax));

      allTotals += itemSumTotal;
      allTotalsIncTax += itemSumTotalIncTax;
      allVendorsTotals += itemSumVendorTotal;
      allVendorsTotalsIncTax += itemSumVendorTotalIncTax;

      // use a custom state to control showing and hiding each items rows
      render.push(
        <Table.Row 
          key={key}  
          style={{
            display: showDetails ? 'table-row' : 'none',
            backgroundColor: '#fafafa'
          }}
        >
          <Table.Cell onClick={() => navigate('/products/form/' + product.product_id)}>
            {product.sku}
          </Table.Cell>
          <Table.Cell>
            {product.barcode}
          </Table.Cell>
          <Table.Cell>
            <div>
              {productName?.en && <div>{productName.en}</div>}
              {productName?.ar && <div>{productName.ar}</div>}
            </div>
          </Table.Cell>
          <Table.Cell>
            {productServiceCity?.text}
          </Table.Cell>
          <Table.Cell>
            {product.quantity}
          </Table.Cell>
          <Table.Cell textAlign="right">
            <div>{(product.total)?.toFixed(2)} SAR</div>
            <div><strong>{(product.total_inc_tax)?.toFixed(2)} SAR</strong></div>
          </Table.Cell>
          <Table.Cell textAlign="right">
            <div>{(itemVendorTotal)?.toFixed(2)} SAR</div>
            <div><strong>{(itemVendorTotal + (itemVendorTotal * product.vendor_tax))?.toFixed(2)} SAR</strong></div>
          </Table.Cell>
        </Table.Row>
      );

      if(
        (
          (key + 1) < purchaseList.length
          && (
            vendorId !== purchaseList[key + 1].vendor_id
            || productId !== purchaseList[key + 1].product_id
          )
        )
        || (key + 1) === purchaseList.length
      ) {
        render.push(
          <Table.Row key={key}>
            <Table.Cell onClick={() => navigate('/products/form/' + product.product_id)}>
              {product.sku}
            </Table.Cell>
            <Table.Cell>
              {product.barcode}
            </Table.Cell>
            <Table.Cell>
              <div>
                {productName?.en && <div>{productName.en}</div>}
                {productName?.ar && <div>{productName.ar}</div>}
                <Label color='orange'>
                {vendorName?.en && vendorName.en} - 
                {vendorName?.ar && vendorName.ar}
                </Label>
              </div>
            </Table.Cell>
            <Table.Cell>
              {productServiceCity?.text}
            </Table.Cell>
            <Table.Cell>
              {itemSumQuantity}
            </Table.Cell>
            <Table.Cell textAlign="right">
              <div>{(itemSumTotal).toFixed(2)} SAR</div>
              <div><strong>{(itemSumTotalIncTax).toFixed(2)} SAR</strong></div>
            </Table.Cell>
            <Table.Cell textAlign="right">
              <div>{(itemSumVendorTotal).toFixed(2)} SAR</div>
              <div><strong>{(itemSumVendorTotalIncTax).toFixed(2)} SAR</strong></div>
            </Table.Cell>
          </Table.Row>
        );

        vendorId = product.vendor_id;
        productId = product.product_id;
        itemSumQuantity = 0;
      }
    });

    render.push(
      <Table.Row positive>
        <Table.Cell colspan={4} textAlign={'right'} />
        <Table.Cell>
          <strong>{sumQuantities}</strong>
        </Table.Cell>
        <Table.Cell textAlign="right">
          <div>{(allTotals).toFixed(2)} SAR</div>
          <div><strong>{(allTotalsIncTax).toFixed(2)} SAR</strong></div>
        </Table.Cell>
        <Table.Cell textAlign="right">
          <div>{(allVendorsTotals).toFixed(2)} SAR</div>
          <div><strong>{(allVendorsTotalsIncTax).toFixed(2)} SAR</strong></div>
        </Table.Cell>
      </Table.Row>
    );

    return <>{render}</>
  }

  const _renderLoadingRow = () => {
    if(!loadingOrders) return null;
    return (
      <Table.Row>
        <Table.Cell colSpan={7} verticalAlign='middle'>
          <Loader active inline size='mini' />
          <div className={'loader-small'}>
            {t('g.loadingMore')}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }

  return <InternalPage>
    <Modal
      size='mini'
      onClose={() => setModal(null)}
      open={(modal && modal.visible) ? true : false}
      header={modal ? modal.title : null}
      content={modal ? modal.message : null}
      actions={modal ? modal.actions : ['Close']}
    />
    
    <Header as='h1'>
      {`${t('s.orders.title')} ${t('s.products.title')}`}
      <Header.Subheader>{t('s.orders.listingDescription')}</Header.Subheader>
      
    </Header>

    <Grid>
      <Grid.Row columns={3}>
        <Grid.Column>
          <Input
            fluid
            icon={{ name: 'search', link: true }}
            placeholder={t('g.search')}
            value={searchTerm}
            onChange={(e, { value }) => {
              setSearchTerm(value)
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown 
            placeholder={t('s.serviceCities.title') || 'Service Cities'}
            fluid
            selection
            clearable
            options={serviceCities || undefined}
            onChange={(e, {value}) => {
              setSelectedServicecCity(value?.toString()||null);
            }}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown 
            placeholder={t('g.orderStatus') || 'Order Status'}
            fluid
            selection
            clearable
            defaultValue={'new'}
            options={[
              {key: 0, text:'New', value:'new'},
              {key: 1,text:'Preparing', value:'preparing'},
              {key: 2,text:'Dispatched', value:'dispatched'},
              {key: 3,text:'In Route', value:'in route'},
              {key: 4,text:'Delivered', value:'delivered'},
              {key: 5,text:'Cancelled', value:'cancelled'},
            ]}
            onChange={(e, {value}) => {
              setOrderStatus(value?.toString()||null);
            }}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Checkbox
            toggle
            onChange={(e, data) => setShowDetails(data.checked||false)}
            checked={showDetails}
            label={'Show details'}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>

    <Table color='violet' selectable>
      <Table.Header>
        <Table.HeaderCell width={3}>{t('g.sku')}</Table.HeaderCell>
        <Table.HeaderCell width={2}>{t('g.barcode')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('g.name')}</Table.HeaderCell>
        <Table.HeaderCell width={3}>{t('s.serviceCities.serviceCity')}</Table.HeaderCell>
        <Table.HeaderCell width={1}>{t('s.orders.quantity')}</Table.HeaderCell>
        <Table.HeaderCell textAlign="right" width={2}>{t('s.orders.salesTotal')}</Table.HeaderCell>
        <Table.HeaderCell textAlign="right" width={2}>{t('s.orders.vendorsTotal')}</Table.HeaderCell>
      </Table.Header>
      {_renderPurchaseList()}
      {_renderLoadingRow()}
    </Table>
  </InternalPage>
}

export default OrdersPurchaseList;